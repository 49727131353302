<template>
  <div :style="{ padding: borderSize }" class="gradient-btn-wrapper">
    <a
      :style="{
        'font-size': fontSize,
        'font-weight': fontWeight,
        color: color,
        height: height,
        width: width,
        padding: padding,
      }"
      class="gradient-btn"
      ><slot></slot
    ></a>
  </div>
</template>

<script>
export default {
  name: "GradientButton",
  props: {
    fontSize: {
      type: String,
    },
    fontWeight: {
      type: String,
    },
    color: {
      type: String,
      default: "#fff",
    },
    borderSize: {
      type: String,
      default: "3px",
    },
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    padding: {
      type: String,
    },
  },
};
</script>

<style lang="sass" scoped>
.gradient-btn-wrapper
  display: inline-block
  cursor: pointer
  border-radius: 9px
  background-size: 200%
  background-image: linear-gradient(89deg, rgba(30,34,36,0) 0%, rgba(18,241,44,1) 40%, rgba(18,241,44,1) 60%, rgba(30,34,36,0) 100%)
  background-position: -20px 0
  will-change: background-position
  transition: background-position .3s ease-in-out
  &:hover
    background-position: -200px 0
    will-change: background-position
    transition: background-position .3s ease-in-out

.gradient-btn
  display: inline-flex
  width: 300px
  height: 60px
  font-size: 22px
  font-weight: 700
  align-items: center
  justify-content: center
  position: relative
  background-size: 200%
  background-image: linear-gradient(89.93deg, #1e2224 0%, #12f12c 40%, #12f12c 60%, #1e2224 100%)
  background-position: -170px 0
  border-radius: 7px
  text-decoration: none
  will-change: background-position
  transition: background-position .3s ease-in-out
  @media(max-width: $computer) and (min-width: $laptop)
    width: 270px
    height: 45px
    font-size: 20px
  @media(max-width: $tablet)
    width: 225px
    height: 44px
    font-size: 16px
  @media(max-width: $mobile)
    width: 234px
    height: 50px
    font-size: 15px
  &:hover
    background-position: -20px 0
    will-change: background-position
    transition: background-position .3s ease-in-out
</style>
