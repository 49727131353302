export default function (fetchData) {
  return {
    callToUser(payload) {
      return fetchData.post("authentication/rest/codes", payload);
    },
    getToken(payload) {
      return fetchData.post("authentication/rest/tokens", payload);
    },
  };
}
