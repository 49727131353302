<template>
  <header-navbar
    v-if="!maintenance || admin === 'dick'"
    v-on:show-modal="showModalWindow"
  ></header-navbar>
  <router-view v-if="!maintenance || admin === 'dick'" v-slot="{ Component }">
    <transition @leave="fixAOS">
      <component :is="Component" v-on:show-modal="showModalWindow" />
    </transition>
  </router-view>
  <technical-maintenance-view
    v-if="maintenance && admin !== 'dick'"
    :maintenanceMessage="maintenanceMessage"
  ></technical-maintenance-view>
  <footer-section v-if="!maintenance || admin === 'dick'"></footer-section>
  <modal-window id="auth" title="Добро пожаловать" centered>
    <form-auth
      :modal="modalOpened"
      v-on:current-start-event="currentStart"
    ></form-auth>
  </modal-window>
  <modal-window id="promocode" title="Промокод" centered>
    <form-promocode :modal="modalOpened"></form-promocode>
  </modal-window>
</template>

<script>
import HeaderNavbar from "@/components/HeaderNavbar";
import FooterSection from "@/components/FooterSection";
import ModalWindow from "@/components/ui/ModalWindow";
import AOS from "aos";
import { Modal, Tooltip } from "bootstrap";
import FormAuth from "@/components/forms/FormAuth";
import FormPromocode from "@/components/forms/FormPromocode";
import { mapGetters, mapMutations, mapActions } from "vuex";
import axios from "axios";
import TechnicalMaintenanceView from "./views/TechnicalMaintenanceView.vue";

export default {
  components: {
    HeaderNavbar,
    FooterSection,
    ModalWindow,
    FormAuth,
    FormPromocode,
    TechnicalMaintenanceView,
  },
  created() {
    this.setToken(localStorage.getItem("token"));
    this.admin = localStorage.getItem("admin");
    this.currentStart();

    //GET FAQ ITEMS
    this.getFaqItems(this.$api);
  },
  mounted() {
    //Technical Maintenance ON/OFF
    this.getMaintenanceStatus();
    setInterval(() => {
      this.getMaintenanceStatus();
    }, 10000);

    //Animations on Scroll
    AOS.init({
      disable: "phone",
    });

    //Tooltip BS5 activate
    let tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    // eslint-disable-next-line no-unused-vars
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  },
  data() {
    return {
      modalOpened: null,
      maintenance: false,
      maintenanceMessage: "",
      cloudStateClosed: false,
      ws: null,
      wsUrl: "",
      admin: "",
    };
  },
  computed: {
    ...mapGetters({
      getToken: "user/getToken",
      getCloudState: "cloud/getCloudState",
    }),
  },
  methods: {
    ...mapMutations({
      setToken: "user/SET_TOKEN",
      setCloudState: "cloud/SET_CLOUD_STATE",
      setQueuePosition: "cloud/SET_QUEUE_POSITION",
    }),
    ...mapActions({
      getProfile: "user/getProfile",
      getFaqItems: "faq/getFaqItems",
    }),
    currentStart() {
      if (this.getToken) {
        this.getProfile(this.$api);
        let refreshProfileData = setInterval(() => {
          if (!this.getToken) clearInterval(refreshProfileData);
          else return this.getProfile(this.$api);
        }, 10000);
      }
      this.wsUrl =
        `wss://` +
        process.env.VUE_APP_API_URL +
        `/cloud-sessions/events?token=${this.getToken}`;
      this.connectWs();
    },
    showModalWindow(id) {
      this.modalWindow = new Modal(document.getElementById(id));
      this.modalWindow.show();
      this.modalOpened = this.modalWindow;
    },
    fixAOS(el, done) {
      AOS.refresh();
      done();
    },
    getMaintenanceStatus() {
      axios
        .get(process.env.VUE_APP_MAINTENANCE_URL, {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        })
        .then((response) => {
          this.maintenance = Object.freeze(response.data.maintenance);
          this.maintenanceMessage = Object.freeze(
            response.data.maintenanceMessage
          );
        });
    },
    connectWs() {
      this.ws = new WebSocket(this.wsUrl);

      this.ws.onmessage = ({ data }) => {
        this.setCloudState(JSON.parse(data).state_type);
        if (this.getCloudState === "WaitingInQueue")
          this.setQueuePosition(JSON.parse(data).queue_number);
        if (this.getCloudState === "Closed") this.cloudStateClosed = true;
        setTimeout(() => {
          this.cloudStateClosed = false;
        }, 10000);
      };

      this.ws.onclose = () => {
        this.setCloudState("wsConnectError");
        let reconnect = setTimeout(() => {
          if (this.getToken) this.connectWs();
          else clearTimeout(reconnect);
        }, 5000);
      };

      this.ws.onerror = () => {
        this.error = "Ошибка подключения к серверу";
        this.ws.close();
      };
    },

    closeWs() {
      this.ws.close(1000);
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.75px;
  color: #fff;
  overflow-x: hidden;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
