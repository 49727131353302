<template>
  <section class="faq">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-title">
            Часто задаваемые <br class="d-inline-block d-md-none" />
            вопросы
          </div>
        </div>

        <div class="col-lg-10 offset-lg-1">
          <div class="accordion" id="faq-accordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Как начать играть?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#faq-accordion"
              >
                <div class="accordion-body">
                  Пожалуйста, посмотрите нашу <a href="#">инструкцию</a>. Мы
                  потратим 2 минуты вашего времени, но сэкономим в&nbsp;разы
                  больше.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  В какие игры я могу играть?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#faq-accordion"
              >
                <div class="accordion-body">
                  Сервис поддерживает все Windows ориентированные программы и
                  игры, всё, что вы запускаете на своём компьютере и даже
                  больше, легко пойдёт на GATOGA.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Что такое виртуальный компьютер?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#faq-accordion"
              >
                <div class="accordion-body">
                  Для Вас он ничем не отличается от компьютера или ноутбука
                  который стоит у вас дома такой же Windows, видеокарта <br />
                  <br />
                  Единственное отличие, попасть на рабочий стол такого
                  виртуального компьютера, можно только через нашу программу
                  удалённого доступа.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Нужно ли покупать игры, чтоб играть в GATOGA?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#faq-accordion"
              >
                <div class="accordion-body">
                  Сервис позволяет играть только в уже приобретённые игры, и не
                  имеет собственного цифрового магазина. <br />
                  <br />
                  В виртуальные игровые компьютеры GATOGA можно скачивать любые
                  игры платные или бесплатные, из любых источников.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  Бесплатный тестовый период
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#faq-accordion"
              >
                <div class="accordion-body">
                  В целях безопасности и целостности сервиса тестовый период
                  временно не предоставляется.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Сколько хранится облако?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#faq-accordion"
              >
                <div class="accordion-body">
                  Данные в облаке хранятся 3 суток с момента последнего запуска
                  облака, по истечение этого времени - облако обнуляется. Во
                  избежание потери данных, рекомендуем запускать облако хотя бы
                  один раз в 3 дня.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 text-center">
          <gradient-button class="d-none">Смотреть больше</gradient-button>
        </div>
      </div>
    </div>
    <div class="faq-blick">
      <img
        class="d-none d-md-inline-block"
        src="@/assets/img/main/faq-blick.svg"
        alt=""
      />
      <img
        class="d-inline-block d-md-none"
        src="@/assets/img/main/faq-blick-m.svg"
        alt=""
      />
    </div>
  </section>
</template>

<script>
import GradientButton from "@/components/ui/GradientButton";

export default {
  name: "TheFaqSection",
  components: {
    GradientButton,
  },
};
</script>

<style lang="sass" scoped>
.faq
  padding: 130px 0 150px
  position: relative
  @media(max-width: $tablet)
    padding: 90px 0 110px
  .section-title
    margin-bottom: 53px

  .accordion
    margin-bottom: 75px
    &-collapse
      background: linear-gradient(180deg, #2750B9 0%, rgba(39, 80, 185, 0) 100%)
    &-item
      background: transparent
      border-color: $blue
      border-width: 2px
      transition: .3s
      &:hover
        transition: .3s
        .accordion-button
          background: $blue
          transition: .3s
      &:first-of-type
        border-top-left-radius: 9px
        border-top-right-radius: 9px
        .accordion-button
          border-top-left-radius: 6px
          border-top-right-radius: 6px
      &:last-of-type
        border-bottom-left-radius: 9px
        border-bottom-right-radius: 9px
    &-button
      background-color: transparent
      font-size: $subtitle-size
      font-weight: 600
      color: #fff
      padding: 40px
      @media(max-width: $computer) and (min-width: $laptop)
        font-size: 20px
      @media(max-width: $tablet)
        font-size: 16px
        padding: 35px
      &:hover, &:active, &:focus
        outline: none
        border-color: unset
        box-shadow: unset
      &:hover
        &:after
          background-image: url("@/assets/img/main/arrow-opened.svg")
      &:not(.collapsed)
        background: $blue
        box-shadow: unset
        &:after
          background-image: url("@/assets/img/main/arrow-opened.svg")
      &:after
        width: 19px
        height: 6px
        background-image: url("@/assets/img/main/arrow-closed.svg")
    &-body
      padding: 0 80px 40px 40px
      font-size: $regular-size
      font-weight: 400
      @media(max-width: $tablet)
        font-size: 12px

  &-blick
    position: absolute
    top: -100px
    left: 0
    right: 0
    margin: auto
    z-index: -1
    img
      width: 100%

  @media(max-width: $mobile)
    padding-bottom: 150px
    .section-title
      margin-bottom: 35px
    .accordion
      margin-bottom: 35px
      &-button
        padding: 30px 15px
        font-size: 14px
      &-body
        font-size: 12px
        padding: 0 15px 30px
</style>
