<template>
  <section class="page-title">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="big-title"><slot></slot></div>
        </div>
      </div>
    </div>
    <figures-parallax class="d-none d-lg-inline-block"></figures-parallax>
    <div class="page-title-bg d-inline-block d-lg-none">
      <img src="@/assets/img/account/background.png" alt="" />
    </div>
  </section>
</template>

<script>
import FiguresParallax from "@/components/FiguresParallax";

export default {
  name: "PageTitleSection",
  components: {
    FiguresParallax,
  },
};
</script>

<style lang="sass" scoped>
.page-title
  position: relative
  padding: 165px 0 105px
  background: url("@/assets/img/page-title-section/blue-blick.svg") no-repeat center center, url("@/assets/img/page-title-section/green-blick.svg") no-repeat right top
  background-size: cover, contain
  overflow: hidden
  @media(max-width: $tablet)
    padding: 120px 0 80px
  @media(max-width: $mobile)
    padding: 70px 0
  &-bg
    position: absolute
    right: 0
    bottom: 0
    @media(max-width: $tablet)
      width: 558px
    img
      max-width: 100%
.figures-parallax
  top: -70px
  @media(max-width: $mobile)
    top: 0
</style>
