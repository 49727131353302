<template>
  <div>
    <kinesis-container>
      <page-title-section
        >Как начать играть <br />
        на&nbsp;GATOGA?</page-title-section
      >
      <section class="video" v-show="false">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 order-1 order-lg-0">
              <ol class="video__list">
                <li>
                  Зарегистрируйтесь, нажав кнопку
                  <b>"Создать аккаунт"</b> вверху сайта.
                  <ol type="a" class="video__list">
                    <li class="mt-2">
                      Зарегистрируйтесь в программе удаленного доступа
                      регистрация.
                    </li>
                    <li>
                      <a href="#" v-scroll-to="'#download'"
                        >Скачайте и установите</a
                      >
                      <b> Parsec</b>
                    </li>
                    <li>
                      Войдите в программу <b>Parsec</b> на своем ПК. (Не
                      забудьте подтвердить почту.)
                    </li>
                  </ol>
                </li>
                <li>Пополните баланс на нужное вам количество времени.</li>
                <li>
                  Нажмите кнопку запустить, и ожидайте успешного уведомления о
                  запуске облака.
                </li>
              </ol>
            </div>
            <div class="col-lg-6 offset-lg-1 order-0 order-lg-1">
              <div class="video__block">
                <a href="#" @click.prevent="playVideo"
                  ><img src="@/assets/img/play-btn.svg" alt="Включить видео"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </kinesis-container>
    <section class="description">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="section-title">
              Подробный вариант инструкции
              <br class="d-none d-md-inline-block d-lg-none" />
              и&nbsp;рекомендации по&nbsp;настройке.
            </div>
          </div>

          <div class="col-md-3" data-sticky-container>
            <ul
              class="description__nav sticky"
              data-margin-top="120"
              data-sticky-for="768"
            >
              <li>
                <a href="#" v-scroll-to="'#registerg'"
                  >Регистрация на&nbsp;GATOGA</a
                >
              </li>
              <li>
                <a href="#" v-scroll-to="'#payment'"
                  >Пополнить баланс на&nbsp;GATOGA</a
                >
              </li>
              <li><a href="#" v-scroll-to="'#download'">Скачать Parsec</a></li>
              <li>
                <a href="#" v-scroll-to="'#registerp'"
                  >Регистрация на&nbsp;Parsec</a
                >
              </li>
              <li>
                <a href="#" v-scroll-to="'#login'">Вход в Parsec</a>
              </li>
              <li>
                <a href="#" v-scroll-to="'#start'">Запуск сессии через сайт</a>
              </li>
              <li>
                <a href="#" v-scroll-to="'#open'"
                  >Открыть Parsec – нажать Connect</a
                >
              </li>
              <li>
                <a href="#" v-scroll-to="'#req'">Системные требования Parsec</a>
              </li>
              <li>
                <a href="#" v-scroll-to="'#tips'">Подсказки Parsec</a>
              </li>
            </ul>
          </div>

          <div class="col-md-9">
            <div class="description__block" id="registerg">
              <div class="description-title">Регистрация на GATOGA</div>
              <swiper
                :slides-per-view="1"
                :space-between="50"
                :navigation="{
                  prevEl: prev,
                  nextEl: next,
                }"
              >
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 1</span></b>
                  <span class="swiper-slide__text"
                    >Нажмите кнопку “Войти / Создать аккаунт” в верхнем правом
                    углу сайта.</span
                  >
                  <a :href="gallery1.img1" data-fancybox="gallery">
                    <img
                      src="@/assets/img/instruction/sliders/1/1.png"
                      alt=""
                    />
                  </a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 2</span></b>
                  <span class="swiper-slide__text"
                    >Введите номер вашего телефона, чтоб получить одноразовый
                    код для входа и нажмите “Далее”.</span
                  >
                  <a :href="gallery1.img2" data-fancybox="gallery">
                    <img
                      src="@/assets/img/instruction/sliders/1/2.png"
                      alt="" /></a
                ></swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 3</span></b>
                  <span class="swiper-slide__text"
                    >Введите последние 4 цифры номера, с которого вам поступил
                    звонок и нажмите “Войти”.</span
                  >
                  <a :href="gallery1.img3" data-fancybox="gallery">
                    <img
                      src="@/assets/img/instruction/sliders/1/3.png"
                      alt=""
                    /> </a
                ></swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 4</span></b>
                  <span class="swiper-slide__text"
                    >Вы попали в Личный кабинет. Регистрация прошла успешно.
                    Переходите к следующему шагу.</span
                  >
                  <a :href="gallery1.img4" data-fancybox="gallery">
                    <img
                      src="@/assets/img/instruction/sliders/1/4.png"
                      alt=""
                    /> </a
                ></swiper-slide>
              </swiper>
              <div class="slider-controlls">
                <a ref="prev" class="slider-btn slider-btn-true">Назад</a>
                <a ref="next" class="slider-btn slider-btn-true">Далее</a>
                <a class="slider-btn slider-prev slider-button-disabled"
                  >Назад</a
                >
                <a
                  class="slider-btn slider-next"
                  href="#"
                  v-scroll-to="'#payment'"
                  >Далее</a
                >
              </div>
            </div>

            <div class="description__block" id="payment">
              <div class="description-title">Пополнить баланс на GATOGA</div>
              <swiper
                :slides-per-view="1"
                :space-between="50"
                :navigation="{
                  prevEl: prev1,
                  nextEl: next1,
                }"
              >
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 1</span></b>
                  <span class="swiper-slide__text"
                    >В навигационном меню личного кабинета нажмите пункт
                    “Пополнить”.</span
                  >
                  <a :href="gallery2.img1" data-fancybox="gallery2">
                    <img
                      src="@/assets/img/instruction/sliders/2/1.png"
                      alt=""
                    />
                  </a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 2</span></b>
                  <span class="swiper-slide__text"
                    >Выберите подходящий вам тариф и нажмите на него.</span
                  >
                  <a :href="gallery2.img2" data-fancybox="gallery2">
                    <img
                      src="@/assets/img/instruction/sliders/2/2.png"
                      alt=""
                    /> </a
                ></swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 3</span></b>
                  <span class="swiper-slide__text"
                    >Выберите подходящий вам способ оплаты.</span
                  >
                  <a :href="gallery2.img3" data-fancybox="gallery2">
                    <img
                      src="@/assets/img/instruction/sliders/2/3.png"
                      alt=""
                    /> </a
                ></swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 4</span></b>
                  <span class="swiper-slide__text"
                    >Вы получили монеты! Нажмите кнопку “Вернуться на сайт”.
                    Переходите к следующему шагу.
                  </span>
                  <a :href="gallery2.img4" data-fancybox="gallery2">
                    <img
                      src="@/assets/img/instruction/sliders/2/4.png"
                      alt="" /></a
                ></swiper-slide>
              </swiper>
              <div class="slider-controlls">
                <a ref="prev1" class="slider-btn slider-btn-true">Назад</a>
                <a ref="next1" class="slider-btn slider-btn-true">Далее</a>
                <a
                  class="slider-btn slider-prev"
                  href="#"
                  v-scroll-to="'#registerg'"
                  >Назад</a
                >
                <a
                  class="slider-btn slider-next"
                  href="#"
                  v-scroll-to="'#download'"
                  >Далее</a
                >
              </div>
            </div>

            <div class="description__block" id="download">
              <div class="description-title">Скачать Parsec</div>
              <swiper
                :slides-per-view="1"
                :space-between="50"
                :navigation="{
                  prevEl: prev2,
                  nextEl: next2,
                }"
              >
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 1</span></b>
                  <span class="swiper-slide__text"
                    >В навигационном меню сайта нажмите на пункт
                    “Скачать”.</span
                  >
                  <a :href="gallery3.img1" data-fancybox="gallery3">
                    <img src="@/assets/img/instruction/sliders/3/1.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 2</span></b>
                  <span class="swiper-slide__text"
                    >Выберите вариант согласно вашей операционной системе.
                  </span>
                  <a :href="gallery3.img2" data-fancybox="gallery3">
                    <img
                      src="@/assets/img/instruction/sliders/3/2.png"
                      alt="" /></a
                ></swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 3</span></b>
                  <span class="swiper-slide__text"
                    >Установите скаченный Parsec. После чего, вам останется
                    только войти в свой аккаунт который мы зарегистрируем на
                    следующем шаге.</span
                  >
                  <a :href="gallery3.img3" data-fancybox="gallery3">
                    <img src="@/assets/img/instruction/sliders/3/3.png" alt=""
                  /></a>
                </swiper-slide>
              </swiper>
              <div class="slider-controlls">
                <a ref="prev2" class="slider-btn slider-btn-true">Назад</a>
                <a ref="next2" class="slider-btn slider-btn-true">Далее</a>
                <a
                  class="slider-btn slider-prev"
                  href="#"
                  v-scroll-to="'#payment'"
                  >Назад</a
                >
                <a
                  class="slider-btn slider-next"
                  href="#"
                  v-scroll-to="'#registerp'"
                  >Далее</a
                >
              </div>
            </div>

            <div class="description__block" id="registerp">
              <div class="description-title">Регистрация на Parsec</div>
              <swiper
                :slides-per-view="1"
                :space-between="50"
                :navigation="{
                  prevEl: prev3,
                  nextEl: next3,
                }"
              >
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 1</span></b>
                  <span class="swiper-slide__text"
                    >Пройдите по&nbsp;
                    <a href="https://parsec.app/signup/" target="_blank"
                      >ссылке</a
                    >, чтобы зарегестрироваться на Parsec. Введите E-mail и имя
                    пользователя.</span
                  >
                  <a :href="gallery4.img1" data-fancybox="gallery4">
                    <img src="@/assets/img/instruction/sliders/4/1.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 2</span></b>
                  <span class="swiper-slide__text"
                    >Введите пароль и поставьте ползунок “Set up two-factor
                    authentication now” в положение выкл. Нажмите кнопку “Create
                    Account”.</span
                  >
                  <a :href="gallery4.img2" data-fancybox="gallery4">
                    <img src="@/assets/img/instruction/sliders/4/2.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 3</span></b>
                  <span class="swiper-slide__text"
                    >Поздравляем, аккаунт Parsec готов, можете закрыть вкладку с
                    Parsec.</span
                  >
                  <a :href="gallery4.img3" data-fancybox="gallery4">
                    <img src="@/assets/img/instruction/sliders/4/3.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 4</span></b>
                  <span class="swiper-slide__text"
                    >Не забудьте подтвердить регистрацию на почте и переходите к
                    следующему шагу.</span
                  >
                  <a :href="gallery4.img4" data-fancybox="gallery4">
                    <img src="@/assets/img/instruction/sliders/4/4.png" alt=""
                  /></a>
                </swiper-slide>
              </swiper>
              <div class="slider-controlls">
                <a ref="prev3" class="slider-btn slider-btn-true">Назад</a>
                <a ref="next3" class="slider-btn slider-btn-true">Далее</a>
                <a
                  class="slider-btn slider-prev"
                  href="#"
                  v-scroll-to="'#download'"
                  >Назад</a
                >
                <a
                  class="slider-btn slider-next"
                  href="#"
                  v-scroll-to="'#login'"
                  >Далее</a
                >
              </div>
            </div>

            <div class="description__block" id="login">
              <div class="description-title">Вход в Parsec</div>
              <swiper
                :slides-per-view="1"
                :space-between="50"
                :navigation="{
                  prevEl: prev5,
                  nextEl: next5,
                }"
              >
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 1</span></b>
                  <span class="swiper-slide__text"
                    >Откройте Приложение Parsec, которое вы скачали и установили
                    в прошлом шаге.</span
                  >
                  <a :href="gallery6.img1" data-fancybox="gallery6">
                    <img src="@/assets/img/instruction/sliders/6/1.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 2</span></b>
                  <span class="swiper-slide__text"
                    >При первом входе в учетную запись Parsec может
                    потребоваться подтвердить свой ip-адрес.</span
                  >
                  <a :href="gallery6.img2" data-fancybox="gallery6">
                    <img src="@/assets/img/instruction/sliders/6/2.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 3</span></b>
                  <span class="swiper-slide__text"
                    >Для подтверждения своего ip-адреса нажмите на кнопку
                    Approve Your New Location в письме. Сразу после того как
                    сайт открылся, вы можете его закрыть.</span
                  >
                  <a :href="gallery6.img3" data-fancybox="gallery6">
                    <img src="@/assets/img/instruction/sliders/6/3.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 4</span></b>
                  <span class="swiper-slide__text"
                    >Поздравляем! Вы успешно авторизовались в Parsec на своем
                    устройстве. Вернитесь в приложение Parsec. Пора уже играть,
                    переходите к следующему шагу.</span
                  >
                  <a :href="gallery6.img4" data-fancybox="gallery6">
                    <img src="@/assets/img/instruction/sliders/6/4.png" alt=""
                  /></a>
                </swiper-slide>
              </swiper>
              <div class="slider-controlls">
                <a ref="prev5" class="slider-btn slider-btn-true">Назад</a>
                <a ref="next5" class="slider-btn slider-btn-true">Далее</a>
                <a
                  class="slider-btn slider-prev"
                  href="#"
                  v-scroll-to="'#registerp'"
                  >Назад</a
                >
                <a
                  class="slider-btn slider-next"
                  href="#"
                  v-scroll-to="'#start'"
                  >Далее</a
                >
              </div>
            </div>

            <div class="description__block" id="start">
              <div class="description-title">Запуск сессии через сайт</div>
              <swiper
                :slides-per-view="1"
                :space-between="50"
                :navigation="{
                  prevEl: prev4,
                  nextEl: next4,
                }"
              >
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 1</span></b>
                  <span class="swiper-slide__text"
                    >В навигационном меню личного кабинета нажмите пункт “Моя
                    GATOGA”.</span
                  >
                  <a :href="gallery5.img1" data-fancybox="gallery5">
                    <img src="@/assets/img/instruction/sliders/5/1.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 2</span></b>
                  <span class="swiper-slide__text"
                    >Нажмите кнопку “Запустить облако” в правом нижнем
                    углу.</span
                  >
                  <a :href="gallery5.img2" data-fancybox="gallery5">
                    <img src="@/assets/img/instruction/sliders/5/2.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 3</span></b>
                  <span class="swiper-slide__text"
                    >Введите ваш E-mail и пароль от Parsec.</span
                  >
                  <a :href="gallery5.img3" data-fancybox="gallery5">
                    <img src="@/assets/img/instruction/sliders/5/3.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 4</span></b>
                  <span class="swiper-slide__text"
                    >Проверьте ваш почтовый ящик и перейдите по ссылке из
                    письма. Подтверждение почты требудется только при первом
                    запуске облака.</span
                  >
                  <a :href="gallery5.img4" data-fancybox="gallery5">
                    <img src="@/assets/img/instruction/sliders/5/4.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 5</span></b>
                  <span class="swiper-slide__text"
                    >Нажмите на голубую кнопку в письме на вашем почтовом ящике.
                    Сразу после того как сайт открылся, вы можете его
                    закрыть.</span
                  >
                  <a :href="gallery5.img5" data-fancybox="gallery5">
                    <img src="@/assets/img/instruction/sliders/5/5.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 6</span></b>
                  <span class="swiper-slide__text"
                    >После подтверждения письма вернитесь на страницу GATOGA и
                    нажмите кнопку “Включить” еще раз.</span
                  >
                  <a :href="gallery5.img6" data-fancybox="gallery5">
                    <img src="@/assets/img/instruction/sliders/5/6.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 7</span></b>
                  <span class="swiper-slide__text"
                    >Дождитесь окончания запуска облака.</span
                  >
                  <a :href="gallery5.img7" data-fancybox="gallery5">
                    <img src="@/assets/img/instruction/sliders/5/7.png" alt=""
                  /></a>
                </swiper-slide>
                <swiper-slide
                  ><b><span class="swiper-slide__step">Шаг 8</span></b>
                  <span class="swiper-slide__text"
                    >Ваше облако запущено! Перейдите в приложение Parsec для
                    начала игровой сессии!</span
                  >
                  <a :href="gallery5.img8" data-fancybox="gallery5">
                    <img src="@/assets/img/instruction/sliders/5/8.png" alt=""
                  /></a>
                </swiper-slide>
              </swiper>
              <div class="slider-controlls">
                <a ref="prev4" class="slider-btn slider-btn-true">Назад</a>
                <a ref="next4" class="slider-btn slider-btn-true">Далее</a>
                <a
                  class="slider-btn slider-prev"
                  href="#"
                  v-scroll-to="'#registerp'"
                  >Назад</a
                >
                <a class="slider-btn slider-next" href="#" v-scroll-to="'#open'"
                  >Далее</a
                >
              </div>
            </div>

            <div class="description__block" id="open">
              <div class="description-title">
                Открыть Parsec – нажать Connect
              </div>

              <div class="description-text">
                Откойте приложение Parsec и нажмите кнопку “Connect”. Готово,
                можете начинать игровую сессию!
              </div>

              <div class="description-img">
                <img src="@/assets/img/instruction/open_parsec.png" alt="" />
              </div>
            </div>

            <div class="description__block">
              <div class="description-warning">
                Сервис находится в бета версии, <br />
                если вы испытываете любой вид дискомфорта при игре через наш
                сервис, <br />
                сразу же пишите в Тех. Поддержку, не выключая облако!<br />
                Потери баланса полностью компенсируются!
              </div>
            </div>

            <div class="description__block">
              <div class="description-text">
                Данные в облаке хранятся 3 суток с момента последнего запуска
                облака, по истечение этого времени — облако обнуляется. Во
                избежание потери данных, рекомендуем запускать облако хотя бы
                один раз в 3 дня.
              </div>
            </div>

            <div class="description__block" id="req">
              <div class="description-title">Системные требования Parsec</div>

              <div class="description-text">
                <b>Минимальные</b> — пропускная способность интернета 3 MB/s.
                <ul>
                  <li>CPU: Любой процессор новее 2011 года.</li>
                  <li>
                    GPU: Intel GMA 950 / NVIDIA 6000 series / AMD Radeon X1000
                    series или лучше, со свежими драйверами.
                  </li>
                  <li>Memory: 2GB DDR3.</li>
                  <li>Разрешение: 1280x768.</li>
                </ul>
              </div>

              <div class="description-text">
                <b>Рекомендованные</b> — пропускная способность интернета 20
                MB/s.
                <ul>
                  <li>CPU: Intel Core Celeron 3000 series или лучше.</li>
                  <li>
                    GPU: Intel HD 3000 / NVIDIA 600 series / AMD Radeon HD 7000
                    series или лучше, со свежими драйверами.
                  </li>
                  <li>Memory: 4GB DDR3.</li>
                  <li>Разрешение: 1920х1080+.</li>
                </ul>
              </div>
            </div>

            <div class="description__block" id="tips">
              <div class="description-title">Подсказки Parsec</div>
              <div class="description-text">
                <ul>
                  <li>
                    <b>Hide Button</b> — скрыть эту кнопку. Если скрыли, и
                    хотите вернуть, нажмите CTRL + SHIFT + M.
                  </li>
                  <li>
                    <b>Chat</b> — открыть чат. Функция реализована не полностью,
                    можете пропустить.
                  </li>
                  <li>
                    <b>Windowed</b> или <b>Fullscreen</b> — переключение режима
                    "полный экран" или "оконный".
                  </li>
                  <li>
                    <b>Decoder:</b> Software, Nvidia, AMD, Intel.
                    Software-медленный режим для старых систем.
                  </li>
                  <li>
                    <b>Bandwidth</b> — ширина канала между вами и облаком. Проще
                    битрейт. Для Full HD разрешение диапазон от 20 до 25 мб\с.
                  </li>
                  <li><b>Disconnect</b> — отключиться. ( CTRL+ ALT + ~ )</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="description-blicks">
        <img
          class="blick-1"
          src="@/assets/img/instruction/blick-1.svg"
          alt=""
        />
        <img
          class="blick-2"
          src="@/assets/img/instruction/blick-2.svg"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
import PageTitleSection from "@/components/PageTitleSection";
import Sticky from "sticky-js";
// eslint-disable-next-line no-unused-vars
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation } from "swiper/core";
import { ref } from "vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "@fancyapps/ui/dist/fancybox.css";

SwiperCore.use([Navigation]);

export default {
  name: "HowToStartView",
  components: {
    PageTitleSection,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const prev = ref(null);
    const next = ref(null);
    const prev1 = ref(null);
    const next1 = ref(null);
    const prev2 = ref(null);
    const next2 = ref(null);
    const prev3 = ref(null);
    const next3 = ref(null);
    const prev4 = ref(null);
    const next4 = ref(null);
    const prev5 = ref(null);
    const next5 = ref(null);
    return {
      modules: [Navigation],
      prev,
      next,
      prev1,
      next1,
      prev2,
      next2,
      prev3,
      next3,
      prev4,
      next4,
      prev5,
      next5,
    };
  },
  data() {
    return {
      gallery1: {
        img1: require("@/assets/img/instruction/sliders/1/1.png"),
        img2: require("@/assets/img/instruction/sliders/1/2.png"),
        img3: require("@/assets/img/instruction/sliders/1/3.png"),
        img4: require("@/assets/img/instruction/sliders/1/4.png"),
      },
      gallery2: {
        img1: require("@/assets/img/instruction/sliders/2/1.png"),
        img2: require("@/assets/img/instruction/sliders/2/2.png"),
        img3: require("@/assets/img/instruction/sliders/2/3.png"),
        img4: require("@/assets/img/instruction/sliders/2/4.png"),
      },
      gallery3: {
        img1: require("@/assets/img/instruction/sliders/3/1.png"),
        img2: require("@/assets/img/instruction/sliders/3/2.png"),
        img3: require("@/assets/img/instruction/sliders/3/3.png"),
      },
      gallery4: {
        img1: require("@/assets/img/instruction/sliders/4/1.png"),
        img2: require("@/assets/img/instruction/sliders/4/2.png"),
        img3: require("@/assets/img/instruction/sliders/4/3.png"),
        img4: require("@/assets/img/instruction/sliders/4/4.png"),
      },
      gallery5: {
        img1: require("@/assets/img/instruction/sliders/5/1.png"),
        img2: require("@/assets/img/instruction/sliders/5/2.png"),
        img3: require("@/assets/img/instruction/sliders/5/3.png"),
        img4: require("@/assets/img/instruction/sliders/5/4.png"),
        img5: require("@/assets/img/instruction/sliders/5/5.png"),
        img6: require("@/assets/img/instruction/sliders/5/6.png"),
        img7: require("@/assets/img/instruction/sliders/5/7.png"),
        img8: require("@/assets/img/instruction/sliders/5/8.png"),
      },
      gallery6: {
        img1: require("@/assets/img/instruction/sliders/6/1.png"),
        img2: require("@/assets/img/instruction/sliders/6/2.png"),
        img3: require("@/assets/img/instruction/sliders/6/3.png"),
        img4: require("@/assets/img/instruction/sliders/6/4.png"),
      },
    };
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    var sticky = new Sticky(".sticky");
  },
  methods: {
    playVideo() {
      let videoBlock = document.querySelector(".video__block");
      videoBlock.innerHTML =
        "<iframe width='700' height='376' src='https://www.youtube.com/embed/rC63uvMGaFU?controls=1&showinfo=0&modestbranding=0' title='Как начать играть?' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>";
    },
  },
};
</script>

<style lang="sass" scoped>
.page-title
  margin-top: -93px
  @media(max-width: $mobile)
    margin-top: 0

.video
  padding: 60px 0
  @media(max-width: $mobile)
    padding: 50px 0
  &__list
    @media(max-width: $tablet)
      font-size: 12px
      li > ol
        padding-left: 15px
    @media(max-width: $mobile)
      font-size: 14px
      li > ol
        padding-left: 15px
    li + li
      margin-top: 10px
    li
      a
        text-decoration: underline
        &:hover
          text-decoration: none
  &__block
    position: relative
    width: 700px
    height: 376px
    background: url("@/assets/img/preview.jpeg") no-repeat center center
    background-size: cover
    border-radius: 9px
    a
      display: inline-block
      position: absolute
      width: 115px
      height: 115px
      top: 0
      left: 0
      right: 0
      bottom: 0
      margin: auto
    @media(max-width: $tablet)
      margin-bottom: 70px
      width: 100%
      height: 428px
    @media(max-width: $mobile)
      margin-bottom: 20px
      max-height: 197px
    iframe
      border-radius: 9px
      max-width: 100%
      @media(max-width: $tablet)
        width: 100%
        height: 428px
      @media(max-width: $mobile)
        max-height: 197px

.description
  position: relative
  padding-bottom: 150px
  @media(max-width: $tablet)
    padding-bottom: 60px
  @media(max-width: $mobile)
    padding-bottom: 30px
  .section-title
    margin-top: 85px
    margin-bottom: 85px
    @media(max-width: $tablet)
      margin-top: 60px
      margin-bottom: 60px
    @media(max-width: $mobile)
      margin-top: 45px
      margin-bottom: 45px
  &__nav
    list-style-type: none
    @media(max-width: $tablet)
      padding-left: 0
    @media(max-width: $mobile)
      margin-bottom: 60px
    li
      a
        font-weight: 700
        color: #95A2AA
        transition: color .3s
        @media(max-width: $tablet)
          font-size: 12px
          line-height: 18px
        &:hover, &:focus, &:active
          color: #fff
          transition: color .3s
    li + li
      margin-top: 30px
  ol
    @media(max-width: $tablet)
      font-size: 12px
    @media(max-width: $mobile)
      font-size: 16px
    li + li
      margin-top: 10px
  &__block
    margin-bottom: 75px
  &-title
    font-weight: 700
    font-size: $subtitle-size
    margin-bottom: 30px
    @media(max-width: $tablet)
      font-size: 16px
    @media(max-width: $mobile)
      font-size: $regular-size
    a
      text-decoration: underline
      &:hover, &:focus, &:active
        text-decoration: none
  &-text
    margin-bottom: 30px
    @media(max-width: $tablet)
      font-size: 12px
    @media(max-width: $mobile)
      font-size: $regular-size
    a
      text-decoration: underline
    ul
      margin: 30px 0
      li
        a
          text-decoration: underline
          &:hover
            text-decoration: none
  &-img
    margin-bottom: 40px
    img
      max-width: 100%
      image-rendering: -webkit-optimize-contrast

  &-warning
    display: block
    padding: 30px 0
    background: $light-blue
    color: #0C0D0D
    font-weight: 700
    border-radius: 9px
    text-align: center
  &-blicks
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: -1
    .blick-1
      position: absolute
      left: 0
      top: -100px
    .blick-2
      position: absolute
      display: inline-block
      right: 0
      bottom: 0
      top: 0
      margin: auto

  .swiper-slide
    &__text
      height: 80px
      display: flex
      align-items: center
      a
        text-decoration: underline
        &:hover
          text-decoration: none
    img
      max-width: 100%
  .slider-controlls
    position: relative
    margin-top: 15px
    display: flex
    justify-content: space-between
    .slider-btn
      border: 2px solid $light-blue
      border-radius: 9px
      padding: 8px 0
      display: inline-block
      width: 219px
      color: $light-blue !important
      font-size: $subtitle-size
      font-weight: 600
      user-select: none
      cursor: pointer
      text-align: center
      transition: .3s ease-in-out
      @media(max-width: $tablet)
        font-size: 20px
      @media(max-width: $mobile)
        font-size: 16px
        max-width: 375px
        width: 100%
      &:hover
        color: #000 !important
        background: $light-blue
        transition: .3s ease-in-out
      &-true
        position: relative
        z-index: 1
    .slider-prev, .slider-next
      position: absolute
      z-index: 0
      @media(max-width: $mobile)
        max-width: 50%
    .slider-prev
      bottom: 0
      left: 0
    .slider-next
      bottom: 0
      right: 0
    .slider-button-disabled
      filter: grayscale(0.8)
      pointer-events: none
    .swiper-button-disabled
      opacity: 0
      z-index: -1
</style>
