<template>
  <div>
    <kinesis-container>
      <page-title-section
        >Условия <br />
        использования <br />
        сервиса&nbsp;GATOGA?</page-title-section
      >
    </kinesis-container>
    <section class="description">
      <div class="container">
        <div class="row">
          <div class="col-md-3" data-sticky-container>
            <ul
              class="description__nav sticky"
              data-margin-top="120"
              data-sticky-for="768"
            >
              <li>
                <a href="#" v-scroll-to="'#general'">Общие положения</a>
              </li>
              <li>
                <a href="#" v-scroll-to="'#use'"
                  >Использование <br />
                  платформы</a
                >
              </li>
              <li><a href="#" v-scroll-to="'#other'">Прочие условия</a></li>
              <li>
                <a href="#" v-scroll-to="'#requisites'">Реквизиты</a>
              </li>
              <li>
                <a target="_blank" href="/docs/privacy_GATOGA.pdf"
                  >Политика обработки персональных данных</a
                >
              </li>
              <li>
                <a target="_blank" href="/docs/user_agreement_GATOGA.pdf"
                  >Пользовательское соглашение</a
                >
              </li>
              <li>
                <a target="_blank" href="/docs/license.pdf">Публичная оферта</a>
              </li>
            </ul>
          </div>

          <div class="col-md-9">
            <div class="description__block" id="general">
              <div class="description-title">Общие положения</div>
              <div class="description-text">
                1.1. Общество с ограниченной ответственностью «ГАТОГА»
                предлагает пользователю сети Интернет (далее — «Пользователь»)
                использовать сервис ГАТОГА, доступный по адресу:
                <a href="https://gatoga.ru">https://gatoga.ru</a> (далее —
                «Платформа»).
              </div>
              <div class="description-text">
                1.2. Использование Платформы регулируется настоящими Условиями,
                Политикой конфиденциальности.
              </div>
              <div class="description-text">
                1.3. Начиная использовать Платформу, ее отдельные функции,
                Пользователь считается принявшим настоящие Условия, а также
                условия всех указанных выше документов, в полном объеме, без
                всяких оговорок и исключений. В случае несогласия Пользователя с
                положениями указанных документов, Пользователь не вправе
                использовать Платформу.
              </div>
              <div class="description-text">
                1.4. Настоящие Условия, равно как указанные выше документы,
                могут быть изменены ООО «ГАТОГА» без какого-либо специального
                уведомления, новая редакция Условий и указанных документов
                вступает в силу с момента их размещения в сети Интернет по
                указанным в настоящем пункте и п. 1.2 настоящих Условий адресам,
                если иное не предусмотрено новой редакцией Условий и указанных
                документов. Действующая редакция Условий всегда находится на
                странице по адресу:
                <a href="https://gatoga.ru">https://gatoga.ru</a>.
              </div>
              <div class="description-text">
                1.5. Если ООО «ГАТОГА» были внесены какие-либо изменения в
                настоящие Условия в порядке, предусмотренном п. 1.4 настоящих
                Условий, с которыми Пользователь не согласен, он обязан
                прекратить использование Платформы.
              </div>
              <div class="description-text">
                1.6. Посредством Платформы Пользователю может быть доступна
                возможность заключить отдельный договор или соглашение, в том
                числе возмездный (-ое), между ООО «ГАТОГА» и Пользователем либо
                лицом, от чьего имени выступает или действует Пользователь, по
                поводу использования веб-служб, услуг или программных продуктов,
                позволяющих удаленно использовать отдельные категории ресурсов
                Платформы, при этом если таким лицом не указано иное,
                Пользователь считается выступающим и действующим от имени такого
                лица.
              </div>
              <div class="description-text">
                1.7. С момента заключения указанного в п. 1.6 настоящих Условий
                договора или соглашения, все отношения по поводу использования
                Платформы Пользователем либо лицом, от чьего имени выступает или
                действует Пользователь, за исключением тех, которые не
                урегулированы таким договором или соглашением, регулируются
                условиями такого договора или соглашения.
              </div>
              <div class="description-text">
                1.8. Используя Платформу, Пользователь дает свое согласие на
                получение сообщений рекламного и информационного характера.
                Пользователь вправе отказаться от получения сообщений путем
                использования соответствующего функционала Платформы или следуя
                инструкциям, указанным в полученном сообщении.
              </div>
              <div class="description-text">
                1.9. ООО «ГАТОГА» может предоставить Пользователю перевод
                настоящих Условий с русского на другие языки, однако в случае
                противоречия между положениями Условий на русском языке и их
                переводом, юридическую силу имеет исключительно русскоязычная
                версия Условий.
              </div>
              <div class="description-text">
                1.10. К настоящим Условиям и отношениям по использованию
                Платформы подлежит применению право Российской Федерации. Любые
                претензии или иски, возникающие из настоящих Условий или
                использования Платформы, должны быть поданы и рассмотрены в суде
                по месту нахождения ООО «ГАТОГА».
              </div>
            </div>

            <div class="description__block" id="use">
              <div class="description-title">
                Использование платформы. Отдельные функции платформы.
              </div>
              <div class="description-text">
                2.1. Платформа предлагает Пользователю возможность бесплатно
                ознакомиться с информацией о Платформе и сервисах, доступных
                через Платформу, а также воспользоваться явными функциями
                Платформы, состоящей из технических и программных средств,
                размещенных на инфраструктуре ООО «ГАТОГА», с учетом положений
                п. 1.6 и п. 1.7 настоящих Условий.
              </div>
              <div class="description-text">
                2.2. Для использования всех или некоторых функций Платформы
                Пользователю необходимо использовать аккаунт, путем авторизации
                на Платформе с помощью учетной записи Пользователя на сервисах
                ООО «ГАТОГА».
              </div>
              <div class="description-text">
                2.3. Использование Платформы возможно только при наличии доступа
                к сети Интернет. Пользователь самостоятельно получает и
                оплачивает такой доступ на условиях и по правилам своего
                оператора связи или провайдера доступа к сети Интернет.
              </div>
              <div class="description-text">
                2.4. Платформа должна использоваться исключительно для
                достижения Пользователем законных и добросовестных целей. Любое
                использование Платформы в нарушении настоящего пункта, включая
                размещение в сети Интернет или на виртуальных серверах ООО
                «ГАТОГА» информации и материалов, которые нарушают действующее
                законодательство, права третьих лиц или указанные в п. 1.2
                настоящих Условий документы, запрещено и является основанием для
                применения к Пользователю мер ответственности, указанных в
                настоящих Условиях.
              </div>
              <div class="description-text">
                2.5. Пользователю могут быть доступны возможности создания и
                управления сервисами и программным обеспечением Пользователя,
                как самим Пользователем, так и лицами, которым Пользователь
                предоставил доступ к таким функциональным возможностям
                Платформы. При этом Пользователь обязан довести до сведения
                указанных лиц положения настоящих Условий и документов,
                указанных в п. 1.2 настоящих Условий, если иное не предусмотрено
                с учетом п. 1.6 и п. 1.7 настоящий Условий.
              </div>
              <div class="description-text">
                2.6. Использование указанных в п. 2.5 настоящих Условий сервисов
                и программного обеспечения Пользователя третьими лицами
                обеспечивается и контролируется Пользователем без участия ООО
                «ГАТОГА».
              </div>
              <div class="description-text">
                2.7. Пользователь дает ООО «ГАТОГА» и его аффилированным лицам
                согласие на обработку персональной информации Пользователя, как
                этот термин определен в Политике конфиденциальности(<a
                  href="https://gatoga.ru"
                  >https://gatoga.ru</a
                >), и лиц, указанных в п. 2.5 настоящих Условий, ООО «ГАТОГА»
                для целей предоставления функциональных возможностей Платформы,
                на передачу ООО «ГАТОГА» (в том числе трансграничной)
                персональной информации Пользователя и указанных лиц, лицам,
                действующим по поручению и заказу ООО «ГАТОГА» и его
                аффилированных лиц, а также третьим лицам для тех же целей,
                равно как и получение такой информации от аффилированных лиц ООО
                «ГАТОГА», а также третьих лиц для тех же целей, а также целей,
                предусмотренных законом, в том числе для целей осуществления
                прав и законных интересов ООО «ГАТОГА» и третьих лиц. ООО
                «ГАТОГА» передает лишь ту персональную информацию Пользователя и
                указанных лиц, которая необходима для указанных целей. Обработка
                ООО «ГАТОГА» персональной информации Пользователя и указанных
                лиц, а также иной информации, загружаемой, передаваемой и
                предоставляемой Пользователем и указанными лицами посредством
                Платформы, осуществляется на условиях Политики
                конфиденциальности, размещенной в сети Интернет по адресу
                <a href="https://gatoga.ru">https://gatoga.ru</a>, в части, не
                противоречащей настоящим Условиям и п. 1.6 и 1.7 настоящих
                Условий.
              </div>
              <div class="description-text">
                2.8. Пользователь настоящим уведомлен, понимает и соглашается с
                тем, что ООО «ГАТОГА» может собирать статистические данные об
                использовании Платформы.
              </div>
              <div class="description-text">
                2.9. Пользователь соглашается с тем, что при использовании
                Платформы для создания и управления указанными в п. 2.5.
                настоящих Условий сервисами и программным обеспечением такие
                сервисы и программное обеспечение, как и весь входящий в них
                контент, могут размещаться на программно-аппаратных ресурсах ООО
                «ГАТОГА» как с указанием на автора и/или правообладателя, так и
                без этого, без обязанности предоставлять отчеты об использовании
                таких сервисов и программного обеспечения, без необходимости
                получения специального разрешения Пользователя и без выплаты
                какого-либо вознаграждения, без ограничении по территории, с
                которой указанные сервисы и программное обеспечение могут быть
                доступны Пользователю и третьим лицам, и территории нахождения
                ресурсов ООО «ГАТОГА», на которой могут размещаться копии таких
                сервисов и программного обеспечения, в течении срока с момента
                размещения таких сервисов и программного обеспечения
                Пользователем на ресурсах ООО «ГАТОГА», до момента удаления и
                программного обеспечения с ресурсов ООО «ГАТОГА», могут
                размещаться совместно с результатами интеллектуальной
                деятельности любых лиц, вне зависимости от художественной
                ценности таких результатов, в любой последовательности. При этом
                Пользователь признает и соглашается, что ООО «ГАТОГА» не обязан
                просматривать и ознакомиться с такими сервисами и программным
                обеспечением (не обязан осуществлять их модерацию). В случае
                если Пользователь не вправе предоставлять ООО «ГАТОГА» указанные
                права использования какого-либо сервиса, программного
                обеспечения, их компонентов или входящего в их состав контента,
                он обязан воздержаться от размещения таких сервиса, программного
                обеспечения.
              </div>
            </div>

            <div class="description__block" id="other">
              <div class="description-title">Прочие условия</div>

              <div class="description-text">
                3.1. Платформа предоставляется на условиях «как есть» (as is).
                ООО «ГАТОГА» не предоставляет никаких гарантий в отношении
                безошибочной и бесперебойной работы Платформы или отдельных ее
                компонентов и/или функций, соответствия Платформы конкретным
                целям Пользователя, не предоставляет никаких иных гарантий,
                прямо не указанных в настоящих Условиях.
              </div>
              <div class="description-text">
                3.2. ООО «ГАТОГА» не несет ответственности за какие-либо прямые
                или косвенные последствия какого-либо использования или
                невозможности использования Платформы и/или убытки, причиненные
                Пользователю и/или третьим сторонам в результате какого-либо
                использования, неиспользования или невозможности использования
                Платформы или отдельных ее компонентов и/или функций, в том
                числе из-за возможных ошибок или сбоев в их работе, за
                исключением случаев, прямо предусмотренных законодательством.
              </div>
              <div class="description-text">
                3.3. ООО «ГАТОГА» вправе устанавливать любые лимиты и
                ограничения на использование Платформы, и может менять их по
                собственному усмотрению, без предварительного уведомления
                Пользователя.
              </div>
              <div class="description-text">
                3.4. ООО «ГАТОГА» вправе на свое усмотрение ограничить или
                полностью заблокировать доступ Пользователя к использованию
                Платформы (или к использованию определенных функциональных
                возможностей Платформы, если это возможно технологически) при
                нарушении настоящих Условий, либо применить к Пользователю иные
                меры с целью соблюдения требований законодательства, прав и
                законных интересов ООО «ГАТОГА» и третьих лиц.
              </div>
              <div class="description-text">
                3.5. ООО «ГАТОГА» вправе по своему усмотрению без уведомления
                Пользователя и без объяснения причин в любое время заблокировать
                доступ лиц, указанных в п. 2.5 настоящих Условий, если иное не
                предусмотрено с учетом п. 1.6 и 1.7 настоящих Условий.
              </div>
              <div class="description-text">
                3.6. Все вопросы и претензии, связанные с
                использованием/невозможностью использования Платформы, а также
                возможным нарушением законодательства и/или прав третьих лиц,
                должны направляться по адресу электронной почты
                <a href="mailto:help@gatoga.ru">help@gatoga.ru</a>.
              </div>
              <div class="description-text">Дата публикации: 04.10.2021г.</div>
            </div>

            <div class="description__block" id="requisites">
              <div class="description-title">Реквизиты</div>

              <div class="description-text">
                КПП 781301001 <br />
                Юридический адрес: 197046, город Санкт-Петербург, Петроградская
                наб, д. 22 литера А, <br />
                помещ. 36-н часть помещения №4
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="description-blicks">
        <img
          class="blick-1"
          src="@/assets/img/instruction/blick-1.svg"
          alt=""
        />
        <img
          class="blick-2"
          src="@/assets/img/instruction/blick-2.svg"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
import PageTitleSection from "@/components/PageTitleSection";
import Sticky from "sticky-js";

export default {
  name: "AgreementView",
  components: {
    PageTitleSection,
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    var sticky = new Sticky(".sticky");
  },
};
</script>

<style lang="sass" scoped>
.page-title
  margin-top: -93px
  @media(max-width: $mobile)
    margin-top: 0
.description
  position: relative
  padding-top: 95px
  padding-bottom: 150px
  @media(max-width: $tablet)
    padding-bottom: 60px
  @media(max-width: $mobile)
    padding-bottom: 30px
  .section-title
    margin-bottom: 85px
    @media(max-width: $tablet)
      margin-bottom: 60px
    @media(max-width: $mobile)
      margin-bottom: 45px
  &__nav
    list-style-type: none
    @media(max-width: $tablet)
      padding-left: 0
    @media(max-width: $mobile)
      margin-bottom: 60px
    li
      a
        font-weight: 700
        color: #95A2AA
        transition: color .3s
        @media(max-width: $tablet)
          font-size: 12px
          line-height: 18px
        &:hover, &:focus, &:active
          color: #fff
          transition: color .3s
    li + li
      margin-top: 30px
  ol
    @media(max-width: $tablet)
      font-size: 12px
    @media(max-width: $mobile)
      font-size: 16px
    li + li
      margin-top: 10px
  &__block
    margin-bottom: 75px
  &-title
    font-weight: 700
    font-size: $subtitle-size
    margin-bottom: 30px
    @media(max-width: $tablet)
      font-size: 16px
    @media(max-width: $mobile)
      font-size: $regular-size
    a
      text-decoration: underline
      &:hover, &:focus, &:active
        text-decoration: none
  &-text
    margin-bottom: 30px
    @media(max-width: $tablet)
      font-size: 12px
    @media(max-width: $mobile)
      font-size: $regular-size
    ul
      margin: 30px 0
  &-img
    margin-bottom: 40px
    img
      max-width: 100%

  &-blicks
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: -1
    .blick-1
      position: absolute
      left: 0
      top: -100px
    .blick-2
      position: absolute
      display: inline-block
      right: 0
      bottom: 0
      top: 0
      margin: auto
</style>
