import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/user";
import HomeView from "../views/HomeView.vue";
import TechnicalMaintenanceView from "@/views/TechnicalMaintenanceView";
import HowToStartView from "@/views/HowToStartView";
import AgreementView from "@/views/AgreementView";
import NewsView from "@/views/NewsView";
import NewsList from "@/views/news/NewsList";
import NewsDetail from "@/views/news/NewsDetail";
import DownloadView from "@/views/DownloadView";
import NotFoundView from "@/views/NotFoundView";
import FAQView from "@/views/FAQView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    alias: "/",
  },
  {
    path: "/maintenance/",
    name: "maintenance",
    component: TechnicalMaintenanceView,
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundView,
  },
  {
    path: "/instruction/",
    name: "instruction",
    component: HowToStartView,
  },
  {
    path: "/download/",
    name: "download",
    component: DownloadView,
  },
  {
    path: "/faq/",
    name: "faq",
    component: FAQView,
  },
  {
    path: "/news",
    name: "news",
    component: NewsView,
    redirect: { name: "news-list" },
    children: [
      {
        path: "/news",
        name: "news-list",
        component: NewsList,
      },
      {
        path: "/news/:id",
        name: "news-detail",
        component: NewsDetail,
      },
    ],
  },
  {
    path: "/agreement",
    name: "agreement",
    component: AgreementView,
  },
  {
    path: "/account",
    name: "account",
    redirect: { name: "AccountMain" },
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountView.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "main",
        name: "AccountMain",
        component: () =>
          import(
            /* webpackChunkName: "accountMain" */ "../views/Account/AccountMain.vue"
          ),
      },
      {
        path: "payment",
        name: "AccountPayment",
        component: () =>
          import(
            /* webpackChunkName: "accountPayment" */ "../views/Account/AccountPayment.vue"
          ),
      },
      {
        path: "payment/success",
        name: "paymentSuccess",
        component: () =>
          import(
            /* webpackChunkName: "accountPaymentSuccess" */ "../views/Account/AccountPaymentSuccess.vue"
          ),
      },
      {
        path: "payment/fail/:errorType?",
        name: "paymentFail",
        component: () =>
          import(
            /* webpackChunkName: "accountPaymentFail" */ "../views/Account/AccountPaymentFail.vue"
          ),
        props: true,
      },
      {
        path: "manage",
        name: "AccountManage",
        redirect: { name: "AccountMain" },
        component: () =>
          import(
            /* webpackChunkName: "accountManage" */ "../views/Account/AccountManage.vue"
          ),
      },
      {
        path: "settings",
        name: "AccountSettings",
        redirect: { name: "AccountMain" },
        component: () =>
          import(
            /* webpackChunkName: "accountSettings" */ "../views/Account/AccountSettings.vue"
          ),
      },
      {
        path: "referral",
        name: "AccountReferral",
        component: () =>
          import(
            /* webpackChunkName: "accountReferral" */ "../views/Account/AccountReferral.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active-link",
  linkExactActiveClass: "active-link",
  scrollBehavior() {
    return { top: 0, x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isAuth;
  const requiredAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (!isAuthenticated && requiredAuth) {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
