export default function (fetchData) {
  return {
    allNews() {
      return fetchData.get("newskmaking/news");
    },
    currentNews(payload) {
      return fetchData.get("newskmaking/news/" + payload);
    },
    lastNews() {
      return fetchData.get("newskmaking/news?limit=1");
    },
  };
}
