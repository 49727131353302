<template>
  <section class="lets-play">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="subtitle-text">Начни играть сегодня.</div>
          <div class="section-title">
            Создай аккаунт <br class="d-none d-md-inline-block d-lg-none" />
            и играй в&nbsp;любые игры <br />
            всего <span>от 42₽* в час.</span>
          </div>
          <div class="lets-play-text">
            Наслаждайтесь высоким качеством <br />
            потоковой передачи на своем устройстве.
          </div>
        </div>
        <div class="col-md-4">
          <div class="lets-play__btn">
            <gradient-button
              v-if="!getAuth"
              @click="this.$parent.$parent.$emit('showModal', 'auth')"
              >Попробовать сейчас</gradient-button
            >
            <router-link to="/account/main" v-else
              ><gradient-button
                >Попробовать сейчас</gradient-button
              ></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <figures-parallax :strengths="[50, 30, 60, 40]"></figures-parallax>
  </section>
</template>

<script>
import GradientButton from "@/components/ui/GradientButton";
import FiguresParallax from "@/components/FiguresParallax";
import { mapGetters } from "vuex";

export default {
  name: "TheLetsPlaySection",
  components: {
    GradientButton,
    FiguresParallax,
  },
  computed: {
    ...mapGetters({ getAuth: "user/getAuth" }),
  },
};
</script>

<style lang="sass" scoped>
.lets-play
  padding: 150px 0 395px
  background: $black
  position: relative
  z-index: 10
  @media(max-width: $tablet)
    padding: 95px 0
  .subtitle-text
    color: $gray
    margin-bottom: 45px
    @media(max-width: $tablet)
      margin-bottom: 30px
  .section-title
    margin-bottom: 45px
    @media(max-width: $tablet)
      margin-bottom: 30px
    span
      color: $light-blue

  @media(max-width: $mobile)
    padding: 70px 0
    .subtitle-text, .section-title
      margin-bottom: 20px
    &-text
      margin-bottom: 55px
</style>
