<template>
  <section id="how" class="how-works">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2
            class="section-title"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            Как работает <span>облачный гейминг?</span>
          </h2>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-6 text-center">
          <div class="how-works__img start">
            <img width="543" src="@/assets/img/main/how1.png" alt="" />
          </div>
        </div>

        <div class="col-md-6">
          <div
            class="how-works__text"
            data-num="01."
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            Облака GATOGA получают <br class="d-inline-block d-md-none" />
            команды
            <br class="d-none d-md-inline-block" />
            от игрока <br class="d-inline-block d-md-none" />
            (мышь, клавиатура, геймпад или VR-очки)
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-6 text-center">
          <div class="how-works__img">
            <img width="643" src="@/assets/img/main/how2.png" alt="" />
          </div>
        </div>

        <div class="col-md-6">
          <div
            class="how-works__text"
            data-num="02."
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            Команды отправляются в запущенную игру или&nbsp;любое
            другое&nbsp;приложение внутрь вашего личного облака
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-6 text-center">
          <div class="how-works__img success">
            <img width="303" src="@/assets/img/main/how3.png" alt="" />
          </div>
        </div>

        <div class="col-md-6">
          <div
            class="how-works__text"
            data-num="03."
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            Все вычисления обрабатывает наше&nbsp;игровое облако,
            <br class="d-inline-block d-md-none" />
            передавая на ваше устройство <br class="d-inline-block d-md-none" />
            видеопоток с&nbsp;низкой&nbsp;задержкой
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HowCloudWorks",
};
</script>

<style lang="sass" scoped>
.how-works
  padding-top: 180px
  padding-bottom: 370px
  position: relative
  z-index: 5
  .row + .row
    margin-top: 30px
  .section-title
    margin-bottom: 70px
    span
      color: $light-blue
      @media(max-width: $tablet)
        display: block

  &__img
    @media(max-width: $tablet)
      &.start
        img
          width: 80%
      &.success
        img
          width: 50%
    img
      max-width: 100%

  &__text
    position: relative
    padding-left: 55px
    @media(max-width: $tablet)
      font-size: 12px
    &:before
      content: ''
      position: absolute
      width: 400px
      height: 400px
      background: url("@/assets/img/main/how-blick.svg") no-repeat
      background-size: cover
      left: -145px
      top: 0
      bottom: 0
      margin: auto
      z-index: -1
      @media(max-width: $tablet)
        width: 271px
        height: 271px
        left: -80px
    &:after
      content: attr(data-num)
      font-size: 110px
      line-height: 116px
      width: 202px
      height: 139px
      font-weight: 800
      color: rgba(39, 80, 185, 0.7)
      position: absolute
      display: block
      left: -5px
      bottom: 0
      top: 25px
      margin: auto
      z-index: -1
      @media(max-width: $tablet)
        font-size: 100px

  @media(max-width: $mobile)
    padding-top: 30px
    .section-title
      span
        display: block
    &__img
      margin-bottom: 30px
      &.start
        img
          width: 85%
      &.success
        img
          width: 65%
      img
        image-rendering: -webkit-optimize-contrast
    &__text
      font-size: 14px
      padding-left: 0
      text-align: center
      margin-bottom: 30px
      &:before
        width: 300px
        height: 300px
        background-size: contain
        left: 0
        right: 0
        top: 0
        bottom: 0
      &:after
        font-size: 100px
        height: 115px
        top: 0
        left: 0
        right: 0
</style>
