export default {
  namespaced: true,
  state: {
    cloudState: "",
    queuePosition: null,
  },
  getters: {
    getCloudState(state) {
      return state.cloudState;
    },
    getQueuePosition(state) {
      return state.queuePosition;
    },
  },
  mutations: {
    SET_CLOUD_STATE(state, payload) {
      state.cloudState = payload;
    },
    SET_QUEUE_POSITION(state, payload) {
      state.queuePosition = payload;
    },
  },
};
