<template>
  <section class="access">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div
            class="section-title"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            Простой доступ <span>без ограничений</span>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-5 offset-md-1 offset-lg-0 order-md-0 order-1">
          <div class="access-text" data-aos="fade-right">
            Весь рабочий стол облака и все игровые <br />
            магазины только для вас
          </div>

          <gradient-button
            v-if="!getAuth"
            @click="this.$parent.$emit('showModal', 'auth')"
            >Начать играть</gradient-button
          >
          <router-link to="/account/main" v-else
            ><gradient-button>Начать играть</gradient-button></router-link
          >
        </div>

        <div class="col-lg-6 offset-lg-1 col-md-5 order-md-1 order-0">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto">
              <div class="access-ico utorrent">
                <img width="77" src="@/assets/img/main/access1.png" alt="" />
              </div>
            </div>
            <div class="col-auto">
              <div class="access-ico battlenet">
                <img width="71" src="@/assets/img/main/access2.png" alt="" />
              </div>
            </div>
            <div class="col-auto">
              <div class="access-ico riot">
                <img src="@/assets/img/main/access3.png" alt="" />
              </div>
            </div>
            <div class="col-auto">
              <div class="access-ico steam">
                <img width="77" src="@/assets/img/main/access4.png" alt="" />
              </div>
            </div>
            <div class="w-100 mb-md-5 mb-4"></div>
            <div class="col-auto">
              <div class="access-ico epic">
                <img width="83" src="@/assets/img/main/access5.png" alt="" />
              </div>
            </div>
            <div class="col-auto">
              <div class="access-ico origin">
                <img width="156" src="@/assets/img/main/access6.png" alt="" />
              </div>
            </div>
            <div class="col-auto">
              <div class="access-ico ubisoft">
                <img width="83" src="@/assets/img/main/access7.png" alt="" />
              </div>
            </div>
            <div class="col-auto">
              <div class="access-ico rockstar">
                <img width="77" src="@/assets/img/main/access8.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GradientButton from "@/components/ui/GradientButton";
import { mapGetters } from "vuex";

export default {
  name: "TheAccessSection",
  components: {
    GradientButton,
  },
  computed: {
    ...mapGetters({ getAuth: "user/getAuth" }),
  },
};
</script>

<style lang="sass" scoped>
.access
  padding-top: 100px
  padding-bottom: 240px
  @media(max-width: $tablet)
    padding-top: 80px
  .section-title
    margin-bottom: 60px
    span
      color: $light-blue
  &-text
    font-size: $regular-size
    margin-bottom: 45px
    @media(max-width: $tablet)
      font-size: 12px
      margin-bottom: 20px

  &-ico
    filter: brightness(0.5)
    will-change: filter
    transition: filter .3s
    &:hover
      filter: brightness(1) drop-shadow(0 0 30px #2750B9)
    img
      max-width: 164px
    @media(max-width: $tablet)
      &.utorrent
        img
          max-width: 44px
      &.battlenet
        img
          max-width: 40px
      &.riot
        img
          max-width: 93px
      &.steam
        img
          max-width: 44px
      &.epic
        img
          max-width: 47px
      &.origin
        img
          max-width: 89px
      &.ubisoft
        img
          max-width: 47px
      &.rockstar
        img
          max-width: 43px

  @media(max-width: $mobile)
    padding-bottom: 70px
    &-ico
      &.utorrent img
        width: 40px
      &.battlenet img
        width: 37px
      &.riot img
        width: 85px
      &.steam img
        width: 40px
      &.epic img
        width: 43px
      &.origin img
        width: 81px
      &.ubisoft img
        width: 43px
      &.rockstar img
        width: 40px
    &-text
      font-size: 14px
      margin-top: 55px
</style>
