<template>
  <div>
    <kinesis-container>
      <the-main-section></the-main-section>
      <the-versus-section></the-versus-section>
    </kinesis-container>
    <the-access-section></the-access-section>
    <the-requirments-section></the-requirments-section>
    <how-cloud-works></how-cloud-works>
    <the-faq-section></the-faq-section>
    <kinesis-container>
      <the-lets-play-section></the-lets-play-section>
    </kinesis-container>
    <section class="annotation">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="annotation-text">
              *при активированной скидке по реферальному промокоду от любого
              игрока GATOGA.
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TheMainSection from "@/views/main/TheMainSection";
import HowCloudWorks from "@/views/main/HowCloudWorks";
import TheAccessSection from "@/views/main/TheAccessSection";
import TheRequirmentsSection from "@/views/main/TheRequirmentsSection";
import TheLetsPlaySection from "@/views/main/TheLetsPlaySection";
import TheFaqSection from "@/views/main/TheFaqSection";
import TheVersusSection from "@/views/main/TheVersusSection";

export default {
  name: "HomeView",
  components: {
    TheMainSection,
    HowCloudWorks,
    TheAccessSection,
    TheRequirmentsSection,
    TheLetsPlaySection,
    TheFaqSection,
    TheVersusSection,
  },
};
</script>

<style lang="sass" scoped>
.annotation
  margin-bottom: 50px
  &-text
    color: #6D7476
</style>
