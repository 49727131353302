export default function (fetchData) {
  return {
    sendPromocode(payload) {
      return fetchData.post(
        "cloud-sessions/rest/profiles/current/promo-code",
        payload
      );
    },
  };
}
