import instance from "@/plugins/api/instance";
import authModule from "./auth";
import userProfileModule from "./userProfile";
import promocodeModule from "./promocode";
import parsecModule from "./parsec";
import paymentModule from "./payment";
import queueModule from "./queue";
import news from "./news";
import faq from "./faq";

export default {
  auth: authModule(instance),
  userProfile: userProfileModule(instance),
  promocode: promocodeModule(instance),
  parsec: parsecModule(instance),
  payment: paymentModule(instance),
  queue: queueModule(instance),
  news: news(instance),
  faq: faq(instance),
};
