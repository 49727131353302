<template>
  <kinesis-container>
    <section class="maintenance">
      <div class="maintenance-parallax">
        <kinesis-element class="parallax p1" :strength="20"
          ><img src="@/assets/img/maintenance/bg1.png" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p2" :strength="25"
          ><img src="@/assets/img/maintenance/bg2.png" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p3" :strength="43"
          ><img src="@/assets/img/maintenance/bg3.png" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p4" :strength="39"
          ><img src="@/assets/img/maintenance/bg4.png" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p5" :strength="6"
          ><img src="@/assets/img/maintenance/bg5.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p6" :strength="10"
          ><img src="@/assets/img/maintenance/bg5.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p7" :strength="6"
          ><img src="@/assets/img/maintenance/bg5.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p8" :strength="11"
          ><img src="@/assets/img/maintenance/bg5.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p9" :strength="9"
          ><img src="@/assets/img/maintenance/bg5.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p10" :strength="9"
          ><img src="@/assets/img/maintenance/bg-blick.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p11" :strength="9"
          ><img src="@/assets/img/maintenance/bg-blick.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p12" :strength="9"
          ><img src="@/assets/img/maintenance/bg-blick.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p13" :strength="9"
          ><img src="@/assets/img/maintenance/bg-blick.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p14" :strength="9"
          ><img src="@/assets/img/maintenance/bg-blick.svg" alt=""
        /></kinesis-element>

        <kinesis-element class="parallax p15" :strength="9"
          ><img src="@/assets/img/maintenance/bg-blick.svg" alt=""
        /></kinesis-element>
      </div>
      <div class="container">
        <div class="row align-items-center maintenance__nav">
          <div class="col-auto">
            <div class="maintenance-logo">
              <img src="@/assets/img/logo.svg" alt="GATOGA Logo" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 offset-md-2 text-center">
            <div class="big-title">Технический перерыв</div>
            <div v-html="maintenanceMessage" class="maintenance-text"></div>
            <div class="maintenance-social">
              <a target="_blank" href="https://vk.com/gatoga"
                ><img src="@/assets/img/vk-soc.svg" alt=""
              /></a>
              <a target="_blank" href="https://t.me/gatoga_cloud"
                ><img src="@/assets/img/tg.svg" alt=""
              /></a>
              <a
                target="_blank"
                href="https://www.youtube.com/results?search_query=GATOGA"
                ><img src="@/assets/img/youtube.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="maintenance-img">
        <img
          src="@/assets/img/maintenance.png"
          alt="Приносим свои извинения за перебои в работе сервиса"
        />
      </div>

      <div class="container" v-if="!newsOff">
        <div class="row">
          <div class="col-12 text-center">
            <div class="maintenance-arrow">
              <img src="@/assets/img/maintenance-arrow.svg" alt="" />
            </div>
          </div>
          <div class="col-12">
            <div class="news">
              <div class="news-date">
                {{ newsDate }}
              </div>
              <div class="news-title">
                {{ lastNews.headline }}
              </div>
              <div class="news-preview" v-html="lastNews.content"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </kinesis-container>
</template>

<script>
export default {
  name: "TechnicalMaintenanceView",
  data() {
    return {
      lastNews: {},
      newsDate: "",
      newsOff: true,
    };
  },
  props: {
    maintenanceMessage: String,
  },
  mounted() {
    //this.getLastNews();
  },
  methods: {
    async getLastNews() {
      // eslint-disable-next-line no-unused-vars
      const data = await this.$api.news.lastNews();
      //this.lastNews = Object.freeze(data.data.results[0]);
      this.newsDate = this.formatCurrentDate(this.lastNews.published_at);
    },
    formatCurrentDate(date) {
      const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      date = date.split("-");
      date[1] = months[date[1] - 1];
      date[2] = Number(date[2]).toString();
      date[0] = [date[2], (date[2] = date[0])][0];
      date = date.join(" ");

      return date;
    },
  },
};
</script>

<style lang="sass" scoped>
.maintenance
  position: relative
  background: url("@/assets/img/maintenance/maintenance-blick.svg") no-repeat top center
  min-height: 100vh
  @media(max-width: $mobile)
    background: url("@/assets/img/maintenance/maintenance-blick-m.svg") no-repeat top center
    background-size: 100% auto
  &-parallax
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    pointer-events: none
    z-index: 0
    .parallax
      position: absolute
      img
        max-width: 100%
        width: 100%
      &.p1
        top: 119px
        left: 31.88vw
        @media(max-width: $laptop)
          left: 21.88vw
        @media(max-width: $tablet)
          left: 12.60vw
        @media(max-width: $mobile)
          left: 30px
          top: 85px
      &.p2
        top: 115px
        right: 33.54vw
        @media(max-width: $laptop)
          right: 23.54vw
        @media(max-width: $tablet)
          right: 12.6vw
        @media(max-width: $mobile)
          right: unset
          left: 16px
          top: 379px
      &.p3
        top: 239px
        right: 26.82vw
        @media(max-width: $laptop)
          right: 16.82vw
        @media(max-width: $tablet)
          right: 5.34vw
        @media(max-width: $mobile)
          right: 40px
          top: 58px
      &.p4
        top: 292px
        left: 26.98vw
        @media(max-width: $laptop)
          left: 16.98vw
        @media(max-width: $tablet)
          left: 6.85vw
        @media(max-width: $mobile)
          left: unset
          right: 15px
          top: 378px
      &.p5
        width: 8px
        height: 8px
        top: 267px
        left: 23.85vw
        @media(max-width: $laptop)
          left: 13.85vw
        @media(max-width: $tablet)
          top: 301px
          left: 7.66vw
        @media(max-width: $mobile)
          left: 37px
          top: 269px
      &.p6
        width: 17px
        height: 17px
        top: 299px
        left: 31.35vw
        @media(max-width: $laptop)
          left: 21.35vw
        @media(max-width: $tablet)
          left: 16.63vw
          top: 462px
        @media(max-width: $mobile)
          left: 119px
          top: 85px
      &.p7
        width: 17px
        height: 17px
        top: 194px
        right: 33.13vw
        @media(max-width: $laptop)
          right: 23.13vw
        @media(max-width: $tablet)
          right: 21.57vw
          top: 212px
        @media(max-width: $mobile)
          right: 24px
          top: 234px
      &.p8
        width: 8px
        height: 8px
        top: 251px
        right: 23.75vw
        @media(max-width: $laptop)
          right: 13.75vw
        @media(max-width: $tablet)
          right: 9.17vw
          top: 306px
        @media(max-width: $mobile)
          right: 91px
          top: 140px
      &.p9
        width: 17px
        height: 17px
        top: 357px
        right: 33.13vw
        @media(max-width: $laptop)
          right: 23.13vw
        @media(max-width: $tablet)
          right: 16.94vw
          top: 453px
        @media(max-width: $mobile)
          right: 24px
          top: 234px
      &.p10
        width: 83px
        height: 83px
        top: 396px
        right: 25.26vw
        @media(max-width: $laptop)
          right: 15.26vw
        @media(max-width: $tablet)
          right: 6.55vw
        @media(max-width: $mobile)
          right: 16px
          top: 224px
      &.p11
        width: 125px
        height: 125px
        top: 191px
        right: 25.26vw
        opacity: .7
        @media(max-width: $laptop)
          right: 15.26vw
        @media(max-width: $tablet)
          right: 9.58vw
          top: 144px
        @media(max-width: $mobile)
          right: unset
          left: 37px
          top: 98px
      &.p12
        width: 42px
        height: 42px
        top: 234px
        right: 21.98vw
        @media(max-width: $laptop)
          right: 11.98vw
        @media(max-width: $tablet)
          right: 5.75vw
          top: 287px
        @media(max-width: $mobile)
          right: 91px
          top: 156px
      &.p13
        width: 149px
        height: 149px
        top: 219px
        left: 25.36vw
        opacity: .6
        @media(max-width: $laptop)
          left: 15.36vw
        @media(max-width: $tablet)
          left: 6.25vw
          top: 378px
        @media(max-width: $mobile)
          left: -15px
          top: 354px
      &.p14
        width: 44px
        height: 44px
        top: 262px
        left: 22.29vw
        @media(max-width: $laptop)
          left: 12.29vw
        @media(max-width: $tablet)
          left: 5.34vw
          top: 316px
        @media(max-width: $mobile)
          left: 43px
          top: 245px
      &.p15
        width: 61px
        height: 61px
        top: 420px
        left: 26.56vw
        @media(max-width: $laptop)
          left: 16.56vw
        @media(max-width: $tablet)
          left: 19.15vw
          top: 196px
        @media(max-width: $mobile)
          left: unset
          right: 60px
          top: 378px
  &__nav
    padding: 25px 0
    position: relative
    z-index: 5
  .big-title
    padding-top: 115px
    margin-bottom: 25px
    position: relative
    z-index: 5
  &-text
    font-size: $subtitle-size
    position: relative
    z-index: 5
    margin-bottom: 20px
    @media(max-width: $computer)
      font-size: 16px
      line-height: 22px
    @media(max-width: $mobile)
      font-size: 14px
      line-height: 20px
  &-img
    @media(max-width: $mobile)
      margin-bottom: 20px
      display: flex
      justify-content: center
    img
      max-width: 100%
      @media(max-width: $mobile)
        max-width: 769px
  &-social
    a
      margin: 0 10px
  &-arrow
    margin-bottom: 50px
    @media(max-width: $mobile)
      margin-bottom: 30px
.news
    margin-bottom: 85px
    @media(max-width: $tablet)
      margin-bottom: 65px
    @media(max-width: $mobile)
      margin-bottom: 45px
    &-date
      font-size: $regular-size
      color: #95A2AA
      margin-bottom: 20px
      @media(max-width: $tablet)
        font-size: 12px
        line-height: 18px
        margin-bottom: 15px
      @media(max-width: $mobile)
        font-size: 10px
        line-height: 16px

    &-title
      font-size: $subtitle-size
      font-weight: 700
      margin-bottom: 40px
      @media(max-width: $tablet)
        font-size: 18px
        line-height: 24px
        margin-bottom: 25px
      @media(max-width: $mobile)
        font-size: 16px
        line-height: 22px

    &-picture
      margin-bottom: 40px
      img
        max-width: 50%
        width: 50%
        border-radius: 9px

    &-preview
      margin-bottom: 40px
      @media(max-width: $tablet)
        font-size: 14px
        line-height: 20px
        margin-bottom: 25px
</style>
