<template>
  <a
    :style="{ width: width + 'px' }"
    class="transparent-btn"
    :class="color"
    :disabled="{ disabled }"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "TransparentButton",
  props: {
    borderSize: {
      type: String,
      default: "3px",
    },
    color: {
      type: String,
    },
    width: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
.transparent-btn
  display: inline-flex
  width: 330px
  height: 60px
  max-width: 100%
  align-items: center
  justify-content: center
  position: relative
  background: linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0) )
  border: solid 2px #12F12C
  border-radius: 9px
  color: $green
  font-size: 22px
  font-weight: 700
  text-decoration: none
  cursor: pointer
  @media(max-width: $tablet)
    width: 225px
    height: 44px
    font-size: 16px
  @media(max-width: $mobile)
    width: 100%
    height: 42px
    font-size: 14px
  &:hover
    color: #ffffff
    background: linear-gradient( 89.93deg, #12F12C 0.06%, #1E2224 100.8% )

.yellow
  border: solid 2px #FAD539
  color: #FAD539
  &:hover
    background: linear-gradient( 89.93deg, #FAD539 0.06%, #1E2224 100.8% )

.red
  border: solid 2px #D61A1A
  color: #D61A1A
  &:hover
    background: linear-gradient( 89.93deg, #D61A1A 0.06%, #1E2224 100.8% )
.blue
  border: solid 2px $light-blue
  color: $light-blue
  &:hover
    background: linear-gradient( 89.93deg, $light-blue 0.06%, #1E2224 100.8% )
</style>
