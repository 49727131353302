export default {
  namespaced: true,
  state: {
    isAuth: false,
    token: null,
    coins: 0,
    parsecNotificationHidden: false,
    referral: null,
    instructionRead: false,
    purgeTime: null,
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getAuth(state) {
      return state.isAuth;
    },
    getParsecNotificationHidden(state) {
      return state.parsecNotificationHidden;
    },
    getCoins(state) {
      return state.coins;
    },
    getReferralCode(state) {
      return state.referral;
    },
    getInstructionRead(state) {
      return state.instructionRead;
    },
    getPurgeTime(state) {
      return state.purgeTime;
    },
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
      payload ? (state.isAuth = true) : (state.isAuth = false);
    },
    DELETE_TOKEN(state) {
      state.token = null;
      state.isAuth = false;
      localStorage.removeItem("token");
    },
    SET_AUTH(state, payload) {
      state.isAuth = payload;
    },
    SET_COINS(state, payload) {
      state.coins = payload;
    },
    SET_PARSEC_NOTIFICATION_HIDDEN(state, payload) {
      state.parsecNotificationHidden = payload;
    },
    SET_REFERRAL(state, payload) {
      state.referral = payload;
    },
    SET_INSTRUCTION_READ(state, payload) {
      state.instructionRead = payload;
    },
    SET_PURGE_TIME(state, payload) {
      state.purgeTime = payload;
    },
  },
  actions: {
    async getProfile(context, payload) {
      try {
        const { data } = await payload.userProfile.getUserProfile();
        context.commit(
          "SET_PARSEC_NOTIFICATION_HIDDEN",
          data.parsec_notification_hidden
        );
        context.commit("SET_REFERRAL", data.rp_promo_code);
        context.commit("SET_COINS", data.available_minutes);
        context.commit("SET_INSTRUCTION_READ", data.instructions_read);
        context.commit("SET_PURGE_TIME", data.purge_time);
      } catch (error) {
        if (error.response.status === 401) {
          context.commit("DELETE_TOKEN");
        }
      }
    },
  },
};
