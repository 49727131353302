<template>
  <section class="main">
    <div class="main__left-blick">
      <img src="@/assets/img/main-blick.svg" alt="" />
    </div>
    <div class="main__illustration d-none d-lg-block">
      <kinesis-element
        class="main__illustration-left-screen"
        :strength="5"
        :originX="100"
      >
        <img
          src="@/assets/img/main/illustration/left-screen.png"
          rel="preload"
          alt=""
        />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-main"
        :strength="5"
        axis="x"
        :originX="100"
      >
        <img
          src="@/assets/img/main/illustration/illustration.webp"
          rel="preload"
          alt=""
        />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax joystick"
        :strength="-30"
      >
        <img src="@/assets/img/main/illustration/joystick.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax stars-1"
        :strength="10"
      >
        <img src="@/assets/img/main/illustration/stars1.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax stars-2"
        :strength="-5"
      >
        <img src="@/assets/img/main/illustration/stars2.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax circle-1"
        :strength="5"
      >
        <img src="@/assets/img/main/illustration/circle1.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax circle-2"
        :strength="-5"
      >
        <img src="@/assets/img/main/illustration/circle2.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax circle-3"
        :strength="15"
      >
        <img src="@/assets/img/main/illustration/circle3.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax circle-4"
        :strength="-25"
      >
        <img src="@/assets/img/main/illustration/circle4.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax circle-5"
        :strength="5"
      >
        <img src="@/assets/img/main/illustration/circle5.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax circle-6"
        :strength="-8"
      >
        <img src="@/assets/img/main/illustration/circle6.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax circle-7"
        :strength="20"
      >
        <img src="@/assets/img/main/illustration/circle7.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax circle-8"
        :strength="-10"
      >
        <img src="@/assets/img/main/illustration/circle8.png" alt="" />
      </kinesis-element>

      <kinesis-element
        class="main__illustration-parallax arrow"
        :strength="45"
        type="depth"
      >
        <img src="@/assets/img/main/illustration/arrow.png" alt="" />
      </kinesis-element>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="main__info">
            <div class="main__info-text">
              {{ lastNews.headline }}
              <br class="d-inline-block d-md-none" />
              <router-link
                :to="'/news/' + lastNews.id"
                class="main__info-text-link"
                >Смотреть больше</router-link
              >
            </div>
          </div>
        </div>

        <div class="col-12 d-lg-none p-0 position-relative">
          <div class="main__mobile-img d-inline-block d-md-none">
            <img src="@/assets/img/main/illustration-m.webp" alt="" />
          </div>
          <div class="main__tablet-img d-none d-md-inline-block">
            <img src="@/assets/img/main/illustration-t.png" alt="" />
          </div>
        </div>

        <div class="col-12">
          <div class="main__block">
            <h1 class="big-title">
              <GlitchedElement :inline="true" :options="{
                  timing: {
                    duration: 3000,
                  },
                }"
                :key="titles[currentTitleIndex]"
              >
                {{ titles[currentTitleIndex] }}
              </GlitchedElement>
              <br />
              НА САМОМ МОЩНОМ
            </h1>
            <div class="main-icons">
              <div class="main-icons__block">
                <div class="main-ico">
                  <img src="@/assets/img/main/ico-1.svg" alt="" />
                </div>
                <div class="main-ico-text">
                  Доступ <br />
                  где удобно
                </div>
              </div>

              <div class="main-icons__block">
                <div class="main-ico">
                  <img src="@/assets/img/main/ico-2.svg" alt="" />
                </div>
                <div class="main-ico-text">
                  На любом <br />
                  устройстве
                </div>
              </div>

              <div class="main-icons__block">
                <div class="main-ico">
                  <img src="@/assets/img/main/ico-3.svg" alt="" />
                </div>
                <div class="main-ico-text">
                  Любые <br />
                  программы
                </div>
              </div>

              <div class="main-icons__block">
                <div class="main-ico">
                  <img src="@/assets/img/main/ico-4.svg" alt="" />
                </div>
                <div class="main-ico-text">
                  Личное <br />
                  облако
                </div>
              </div>
            </div>
            <gradient-button
              v-if="!getAuth"
              @click="this.$parent.$parent.$emit('showModal', 'auth')"
              >Начать</gradient-button
            >
            <router-link to="/account/main" v-else
              ><gradient-button>Начать</gradient-button></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GradientButton from "@/components/ui/GradientButton";
import { mapGetters } from "vuex";

export default {
  name: "TheMainSection",
  components: {
    GradientButton
  },
  data() {
    return {
      lastNews: {
        headline: "Свежие обновления и новости GATOGA",
        id: "",
      },
      titles: [
        'ВСЕ ВАШИ ЖЕЛАНИЯ',
        'ИГРЫ',
        'CAD ПРОЕКТИРОВАНИЕ',
        'РАЗРАБОТКА ИГР',
        '3D МОДЕЛИРОВАНИЕ',
        'РЕНДЕРИНГ',
        'СОЗДАНИЕ A.I.',
        'ПРОГРАМИРОВАНИЕ'
      ],
      currentTitleIndex: 0
    };
  },
  beforeMount() {
    this.getLastNews();
  },
  mounted() {
    setInterval(() => {
      this.currentTitleIndex =
        (this.currentTitleIndex + 1) % this.titles.length;
    }, 2000);
  },
  computed: {
    ...mapGetters({ getAuth: "user/getAuth" }),
  },
  methods: {
    async getLastNews() {
      const data = await this.$api.news.lastNews();
      data.data.results.length > 0
        ? (this.lastNews = Object.freeze(data.data.results[0]))
        : "";
    },
  },
};
</script>

<style lang="sass" scoped>
.main
  position: relative
  z-index: 1
  &__left-blick
    position: absolute
    left: 0
    top: -96px
    pointer-events: none
    @media(max-width: $tablet)
      display: none
  &-blick
    position: absolute
    right: 0
    top: -96px
    z-index: -1
  &__illustration
    position: absolute
    width: 100%
    height: 100%
    top: 70px
    right: 0
    pointer-events: none
    @media(max-width: $computer)
      top: 7.143vw
    &-left-screen
      position: absolute
      top: -80px
      left: 0
      @media(max-width: $computer)
        display: none
      img
        width: 21.88vw
        max-width: 420px
    &-main
      width: 66.979vw
      max-width: 1188px
      position: absolute
      right: 0
      img
        max-width: 100%
        image-rendering: -webkit-optimize-contrast
    &-parallax
      position: absolute
      img
        max-width: 100%
        image-rendering: -webkit-optimize-contrast
      &.joystick
        width: 13.281vw
        height: 13.281vw
        max-width: 255px
        top: 16.406vw
        right: 22.760vw
        @media(min-width: 1921px)
          top: 315px
          right: 437px
      &.stars-1
        width: 6.823vw
        height: 8.906vw
        max-width: 131px
        top: 16.979vw
        right: 13.281vw
        @media(min-width: 1921px)
          top: 326px
          right: 255px
      &.stars-2
        width: 4.323vw
        height: 6.667vw
        max-width: 83px
        top: 13.021vw
        right: 2.604vw
        @media(min-width: 1921px)
          top: 250px
          right: 50px
      &.circle-1
        width: 1.823vw
        height: 1.823vw
        max-width: 35px
        top: 36.198vw
        right: 60.104vw
        @media(min-width: 1921px)
          top: 695px
          right: 1154px
      &.circle-2
        width: 0.938vw
        height: 0.938vw
        max-width: 18px
        top: 34.792vw
        right: 47.656vw
        @media(min-width: 1921px)
          top: 668px
          right: 915px
      &.circle-3
        width: 1.406vw
        height: 1.406vw
        max-width: 27px
        top: 26.563vw
        right: 38.021vw
        @media(min-width: 1921px)
          top: 510px
          right: 730px
      &.circle-4
        width: 2.708vw
        height: 2.708vw
        max-width: 52px
        top: 35.104vw
        right: 32.917vw
        z-index: 2
        @media(min-width: 1921px)
          top: 674px
          right: 632px
      &.circle-5
        width: 1.094vw
        height: 1.094vw
        max-width: 21px
        top: 34.375vw
        right: 31.875vw
        z-index: 1
        @media(min-width: 1921px)
          top: 660px
          right: 612px
      &.circle-6
        width: 1.094vw
        height: 1.094vw
        max-width: 21px
        top: 30.990vw
        right: 21.354vw
        @media(min-width: 1921px)
          top: 595px
          right: 410px
      &.circle-7
        width: 2.135vw
        height: 1.979vw
        max-width: 42px
        top: 35.104vw
        right: 12.240vw
        @media(min-width: 1921px)
          top: 674px
          right: 235px
      &.circle-8
        width: 1.771vw
        height: 1.667vw
        max-width: 34px
        top: 26.823vw
        right: 1.042vw
        @media(min-width: 1921px)
          top: 515px
          right: 20px
      &.arrow
        width: 4.167vw
        height: 4.740vw
        max-width: 57px
        top: 26.302vw
        right: 54.688vw
        @media(min-width: 1921px)
          top: 505px
          right: 1050px
  &__info
    display: flex
    position: relative
    align-items: center
    justify-content: center
    height: 41px
    border-radius: 9px
    color: $black
    background: $light-blue
    z-index: 10
    @media(max-width: $tablet)
      height: 30px
    @media(max-width: $mobile)
      padding: 10px 0
      height: auto
    &-text
      font-size: 12px
      text-transform: uppercase
      @media(max-width: $tablet)
        font-size: 9px
      @media(max-width: $mobile)
        font-size: 11px
        text-align: center
      font-weight: 600
      &-link
        text-transform: none
        font-size: 12px
        @media(max-width: $tablet)
          font-size: 9px
          margin-left: 15px
        @media(max-width: $mobile)
          font-size: 11px
          display: inline-block
          margin-left: 0
        font-weight: 800
        color: #1e2224
        margin-left: 20px

  &__block
    max-width: 985px
    padding: 150px 0 95px
    position: relative
    z-index: 5
    @media(max-width: $laptop)
      max-width: 750px
      padding: 80px 0 75px
    @media(max-width: $computer) and (min-width: $laptop)
      padding: 80px 0 95px
      max-width: 750px
    @media(max-width: $tablet)
      max-width: 440px
      padding: 25px 0 95px
    .subtitle-text
      color: $gray
      margin-bottom: 25px
      @media(max-width: $tablet)
        font-size: 16px
    .section-title
      margin-bottom: 25px
    .big-title
      margin-bottom: 25px
      @media(max-width: $tablet)
        font-size: 36px

  &-icons
    display: flex
    &__block
      max-width: 161px
      width: auto
      display: inline-block
      text-align: center
      @media(max-width: $mobile)
        max-width: 90px
    &__block + &__block
      margin-left: 30px
      @media(max-width: $mobile)
        margin-left: 10px
  &-ico
    margin-bottom: 20px
    @media(max-width: $mobile)
      margin-bottom: 10px
      img
        max-width: 42px
  &-ico-text
    margin-bottom: 40px
    @media(max-width: $computer)
      font-size: 14px
    @media(max-width: $tablet)
      font-size: 12px
    @media(max-width: $mobile)
      font-size: 11px
  &-text
    font-size: $subtitle-size
    font-weight: 400
    margin-bottom: 35px
    max-width: 500px
    @media(max-width: $computer) and (min-width: $laptop)
      font-size: 18px
    @media(max-width: $laptop)
      font-size: 20px
    @media(max-width: $tablet)
      font-size: 16px

  @media(max-width: $tablet)
    &__tablet-img
      position: absolute
      right: 0
      top: 30px
      img
        position: relative
        z-index: -1
  @media(max-width: $mobile)
    &__mobile-img
      img
        max-width: 100%
    &-text
      font-size: 14px
    &__block
      margin-top: -80px
      padding-bottom: 30px
</style>
