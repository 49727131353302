<template>
  <div class="news-block">
    <router-view v-slot="{ Component }">
      <transition mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "NewsView",
};
</script>

<style lang="sass" scoped></style>
