<template>
  <nav class="nav">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-auto">
          <router-link to="/">
            <div class="nav__logo">
              <img src="../assets/img/logo.svg" alt="Gatoga Logo" />
              <span class="beta">beta</span>
            </div>
          </router-link>
        </div>

        <div class="col-auto d-md-inline-block d-none">
          <div class="nav__wrapper">
            <ul class="nav__menu">
              <li class="nav__menu-item download">
                <router-link
                  class="nav__menu-item-link"
                  active-class="menu-active"
                  to="/download/"
                  >Скачать</router-link
                >
              </li>
              <li class="nav__menu-item">
                <router-link
                  class="nav__menu-item-link"
                  active-class="menu-active"
                  to="/instruction/"
                  >Как начать</router-link
                >
              </li>
              <li class="nav__menu-item">
                <router-link
                  class="nav__menu-item-link"
                  active-class="menu-active"
                  to="/news/"
                  >Новости</router-link
                >
              </li>
              <li class="nav__menu-item">
                <router-link
                  class="nav__menu-item-link"
                  active-class="menu-active"
                  to="/faq/"
                  >Центр помощи</router-link
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="col text-end d-none d-md-inline-block">
          <div class="nav__login" v-if="!getAuth">
            <a class="nav__login-btn" href="#" @click="login"
              >Войти | Создать аккаунт</a
            >
          </div>
          <div class="nav__profile d-inline-flex" v-else>
            <div
              v-if="!isPurgeTime"
              id="battery"
              class="nav__profile-block battery"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="batteryTooltip"
            >
              <div class="nav__profile-ico battery">
                <img :src="batteryColor" alt="" />
              </div>
            </div>

            <div
              class="nav__profile-block"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Баланс"
            >
              <router-link
                class="d-inline-flex"
                :to="{ name: 'AccountPayment' }"
              >
                <div class="nav__profile-ico coins">
                  <img src="@/assets/img/coin.png" alt="" />
                </div>
                <div class="nav__profile-text">{{ getCoins }}</div>
              </router-link>
            </div>

            <div
              class="nav__profile-block download"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Профиль"
            >
              <div class="nav__profile-ico profile">
                <router-link to="/account/main">
                  <img src="@/assets/img/profile-ico.svg" alt=""
                /></router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="col text-end d-inline-block d-md-none">
          <div class="nav__mobile">
            <a href="#" @click.prevent="toggleMobileMenu">
              <img src="@/assets/img/burger.svg" alt="Меню" />
            </a>

            <div class="nav__mobile-menu__block" ref="mobileMenu">
              <div
                class="nav__mobile-menu-overlay"
                @click.prevent="toggleMobileMenu"
              ></div>
              <div class="nav__mobile-menu">
                <div class="nav__mobile-menu-close">
                  <a href="#" @click.prevent="toggleMobileMenu"
                    ><img src="@/assets/img/burger-arrow.svg" alt=""
                  /></a>
                </div>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-12">
                      <div class="nav__mobile-menu-logo">
                        <router-link to="/" @click="toggleMobileMenu">
                          <img src="@/assets/img/mobile-menu-logo.png" alt=""
                        /></router-link>
                      </div>
                    </div>
                    <div class="col-12">
                      <ul class="nav__mobile-menu-list">
                        <li v-if="getAuth">
                          <router-link
                            to="/account/main"
                            @click="toggleMobileMenu"
                            >Профиль</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/download" @click="toggleMobileMenu"
                            >Скачать</router-link
                          >
                        </li>
                        <li>
                          <router-link
                            to="/instruction"
                            @click="toggleMobileMenu"
                            >Как начать</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/news" @click="toggleMobileMenu"
                            >Новости</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/faq" @click="toggleMobileMenu"
                            >Центр помощи</router-link
                          >
                        </li>
                      </ul>
                    </div>

                    <div class="col-12">
                      <div class="nav__mobile-menu-loginout">
                        <a @click="mobileLogin" v-if="!getAuth">
                          <div
                            class="row align-items-center justify-content-start"
                          >
                            <div class="col-auto pr-0">
                              <img src="@/assets/img/log-in-out.svg" alt="" />
                            </div>
                            <div class="col p-0 text-start">
                              Войти <br />
                              Создать аккаунт
                            </div>
                          </div>
                        </a>

                        <a class="logout" @click.prevent="mobileLogout" v-else>
                          <div
                            class="row align-items-center justify-content-start"
                          >
                            <div class="col-auto pr-0">
                              <img src="@/assets/img/log-in-out.svg" alt="" />
                            </div>
                            <div class="col p-0 text-start">Выйти</div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

export default {
  name: "HeaderNavbar",
  emits: ["showModal"],
  data() {
    return {
      isPurgeTime: true,
      batteryTooltip: null,
      batteryColor: "",
    };
  },
  mounted() {
    let nav = document.querySelector("nav");
    let appSection = document.querySelector("#app");
    let scrollPos = window.pageYOffset;

    addEventListener("scroll", function () {
      scrollPos = window.pageYOffset;

      if (scrollPos >= 0 && scrollPos <= nav.offsetHeight) {
        nav.classList.remove("fixed-hidden");
        nav.classList.remove("fixed");
        appSection.style.marginTop = 0;
      } else if (scrollPos > nav.offsetHeight && scrollPos < 800) {
        nav.classList.add("fixed-hidden");
        nav.classList.remove("fixed");
        appSection.style.marginTop = nav.offsetHeight + "px";
      } else if (scrollPos >= 800) {
        nav.classList.remove("fixed-hidden");
        nav.classList.add("fixed");
        appSection.style.marginTop = nav.offsetHeight + "px";
      }
    });
    addEventListener("DOMContentLoaded", function () {
      let battery = document.getElementById("battery");
      // eslint-disable-next-line no-undef,no-unused-vars
      let tooltip = new bootstrap.Tooltip(battery, {
        title: this.batteryTooltip,
        delay: 100,
        trigger: "hover",
      });
    });
  },
  watch: {
    getPurgeTime(newVal) {
      if (newVal == "none") {
        this.isPurgeTime = true;
        console.log("NONE DIFF");
      } else {
        let purgeTime = new Date(newVal * 1000);
        let diff = moment(purgeTime).diff(moment(), "h");
        if (diff >= 24) {
          this.isPurgeTime = false;
          let purgeDays = Math.floor(diff / 24);
          if (purgeDays === 1) {
            this.batteryColor = require("@/assets/img/battery-yellow.svg");
            this.batteryTooltip =
              "Время до удаления вашего образа " + purgeDays + " день.";
          } else {
            this.batteryColor = require("@/assets/img/battery-green.svg");
            this.batteryTooltip =
              "Время до удаления вашего образа " + purgeDays + " дня.";
          }
        } else if (diff < 24 && diff > 1) {
          this.isPurgeTime = false;
          this.batteryColor = require("@/assets/img/battery-red.svg");
          this.batteryTooltip =
            "Время до удаления вашего образа " + diff + " часов.";
        } else if (diff < 1) {
          this.isPurgeTime = true;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getAuth: "user/getAuth",
      getCoins: "user/getCoins",
      getPurgeTime: "user/getPurgeTime",
    }),
  },
  methods: {
    ...mapMutations({ deleteToken: "user/DELETE_TOKEN" }),
    login() {
      this.$emit("showModal", "auth");
    },
    logout() {
      this.deleteToken();
      this.$router.push({ name: "home" });
    },
    toggleMobileMenu() {
      this.$refs.mobileMenu.classList.toggle("opened");
      if (this.$refs.mobileMenu.classList.contains("opened"))
        document.body.style.overflow = "hidden";
      else document.body.style.overflow = "auto";
    },
    mobileLogin() {
      this.toggleMobileMenu();
      this.login();
    },
    mobileLogout() {
      this.toggleMobileMenu();
      this.logout();
    },
    confirmExit(text, func) {
      this.confirmWindow = confirm(text);
      if (this.confirmWindow) {
        func();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.nav
  width: 100%
  padding: 25px 0
  position: relative
  opacity: 1
  max-height: 93px
  z-index: 100
  @media(max-width: $tablet)
    padding: 20px 0
  @media(max-width: $mobile)
    padding: 15px 0
  &__logo
    display: flex
    align-items: flex-end
    img
      margin-right: 5px
      @media(max-width: $tablet)
        max-width: 90px
    .beta
      color: $light-blue
      font-size: 9px
      padding-bottom: 4px
      @media(max-width: $tablet)
        padding-bottom: 3px

  &__wrapper
    margin-left: 50px
    @media(max-width: $tablet)
      margin-left: 25px

  &__menu
    list-style-type: none
    margin-bottom: 0
    padding-left: 0
    position: relative
    .menu-active
      &:after
        content: ''
        width: 100%
        height: 4px
        border-bottom-right-radius: 3px
        border-bottom-left-radius: 3px
        pointer-events: none
        background: $light-blue
        position: absolute
        top: -25px
        left: 0
        opacity: 1
        will-change: opacity
        transition: opacity .3s
        @media(max-width: $tablet)
          top: -20px
    &-item + &-item
      margin-left: 30px
    &-item
      display: inline-flex
      align-items: center
      position: relative
      height: 43px
      &:after
        content: ''
        width: 100%
        height: 4px
        border-bottom-right-radius: 3px
        border-bottom-left-radius: 3px
        pointer-events: none
        background: $light-blue
        position: absolute
        top: -25px
        left: 0
        opacity: 0
        will-change: opacity
        transition: opacity .3s
        @media(max-width: $tablet)
          top: -20px
      &:hover
        &:after
          opacity: 1
          will-change: opacity
          transition: opacity .3s
      &-link
        font-size: 12px
        display: inline-block
        @media(max-width: $tablet)
          font-size: 9px

      .active
        &:after
          opacity: 1
          will-change: opacity
          transition: opacity .3s

  &__login-btn
    border: 2px solid $light-blue
    border-radius: 9px
    padding: 8px 0
    display: inline-block
    width: 219px
    color: $light-blue !important
    font-size: 12px
    font-weight: 600
    user-select: none
    cursor: pointer
    text-align: center
    transition: .3s ease-in-out
    @media(max-width: $tablet)
      font-size: 12px
    @media(max-width: $mobile)
      font-size: 16px
      max-width: 375px
      width: 100%
    &:hover
      color: #000 !important
      background: $light-blue
      transition: .3s ease-in-out
  .download
    &:hover
      .nav__download
        opacity: 1
        pointer-events: auto
        will-change: opacity
        transition: opacity .2s
  &__download
    list-style-type: none
    padding: 10px 0
    background: #313637
    border-radius: 9px
    width: 145px
    position: absolute
    top: 100%
    left: -40px
    opacity: 0
    pointer-events: none
    will-change: opacity
    transition: opacity .2s
    &-item
      a
        font-size: 12px
        display: block
        padding: 3px 15px
        will-change: background
        transition: background .3s
        &:hover
          background: #6D7476
          will-change: background
          transition: background .3s
  &__profile
    @media(max-width: $tablet)
      padding-right: 30px
    &-block
      display: inline-flex
      height: 43px
      position: relative
      align-items: center
      justify-content: center
    &-block + &-block
      margin-left: 30px
    &-text
      margin-left: 5px
      font-weight: 600
    &-ico
      width: 27px
      height: 27px
      display: flex
      align-items: center
      justify-content: center
      border-radius: 50%
      transition: .3s
      &.battery
        img
          max-width: 100%
      @media(max-width: $tablet)
        width: 20px
        height: 20px
        &.battery
          img
            max-width: 100%
            width: 15px
        &.profile
          img
            max-width: 100%
            width: 12px
        &.coins
          img
            width: 23px
            height: 23px
      &:hover
        transition: .3s
      &.support
        background: rgba(49, 54, 55, 0.7)
        &:hover
          background: rgba(49, 54, 55, 1)
      &.coins
        &:hover
          filter: brightness(1.2)
        img
          padding-bottom: 2px
          width: 27px
      &.profile
        background: rgba(18, 241, 44, 0.25)
        &:hover
          background: rgba(18, 241, 44, 0.5)
    .nav__download
      width: 104px
      &-item
        text-align: left

  &__mobile-menu__block
    position: fixed
    display: flex
    top: 0
    right: 0
    width: 100%
    height: 100%
    transform: translateX(150%)
    transition: transform .3s ease-in-out
  &__mobile-menu-overlay
    width: 35%
    height: 100%
  &__mobile-menu
    position: relative
    width: 65%
    height: 100%
    padding: 50px 0 0
    background: $menu-background
    box-shadow: 0 0 49px rgba(7, 7, 7, 0.9)
    &-close
      position: absolute
      left: -18px
      top: 15px
    &-logo
      text-align: center
      margin-bottom: 30px
    &-list
      text-align: left
      list-style-type: none
      padding-left: 0
      li + li
        margin-top: 40px
      li
        font-size: 14px
    &-download-list
      list-style-type: none
      padding-left: 15px
      padding-top: 10px
      li + li
        margin-top: 10px
    &-loginout
      margin-top: 40px
      display: flex
      align-items: center
      justify-content: flex-start
      a
        font-size: 14px
        font-weight: 600
      .logout
        img
          transform: rotate(180deg)
  .opened
    transform: translateX(0%)
    transition: transform .3s ease-in-out
.fixed-hidden
  position: fixed
  top: -96px
  transition: .3s
.fixed
  position: fixed
  background: rgba(12, 13, 13, 0.9)
  top: 0
  opacity: 1
  transition: .3s
</style>
