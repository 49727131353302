import { createStore } from "vuex";
import user from "./user";
import queue from "./queue";
import cloud from "./cloud";
import faq from "./faq";

const store = createStore({
  modules: { user, queue, cloud, faq },
});

export default store;
