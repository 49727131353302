<template>
  <kinesis-container>
    <section class="news-detail" v-cloak>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="back">
              <router-link to="/news"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0303 4.78033C10.3232 4.48744 10.3232 4.01256 10.0303 3.71967C9.73744 3.42678 9.26256 3.42678 8.96967 3.71967L10.0303 4.78033ZM5.75 8L5.21967 7.46967C4.92678 7.76256 4.92678 8.23744 5.21967 8.53033L5.75 8ZM8.96967 12.2803C9.26256 12.5732 9.73744 12.5732 10.0303 12.2803C10.3232 11.9874 10.3232 11.5126 10.0303 11.2197L8.96967 12.2803ZM8.96967 3.71967L5.21967 7.46967L6.28033 8.53033L10.0303 4.78033L8.96967 3.71967ZM5.21967 8.53033L8.96967 12.2803L10.0303 11.2197L6.28033 7.46967L5.21967 8.53033ZM14 8C14 11.3137 11.3137 14 8 14V15.5C12.1421 15.5 15.5 12.1421 15.5 8H14ZM8 14C4.68629 14 2 11.3137 2 8H0.5C0.5 12.1421 3.85786 15.5 8 15.5V14ZM2 8C2 4.68629 4.68629 2 8 2V0.5C3.85786 0.5 0.5 3.85786 0.5 8H2ZM8 2C11.3137 2 14 4.68629 14 8H15.5C15.5 3.85786 12.1421 0.5 8 0.5V2Z"
                    fill="#4FC2F6"
                  /></svg
                >Назад</router-link
              >
            </div>
          </div>
          <div class="col-12">
            <div class="news-detail-picture" v-if="currentNews.picture">
              <img :src="'https://' + apiUrl + currentNews.picture" alt="" />
            </div>

            <div class="news-detail-date">
              {{ currentNewsDate }}
            </div>
            <div class="news-detail-title">
              {{ currentNews.headline }}
            </div>
            <div class="news-detail-preview" v-html="currentNews.content"></div>
          </div>
        </div>
      </div>
      <div class="figures">
        <figures-parallax></figures-parallax>
      </div>
    </section>
  </kinesis-container>
</template>

<script>
import FiguresParallax from "@/components/FiguresParallax";

export default {
  name: "NewsDetail",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    FiguresParallax,
  },
  data() {
    return {
      currentNews: Object,
      currentNewsDate: "",
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    this.getCurrentNews();
  },
  methods: {
    async getCurrentNews() {
      try {
        const data = (await this.$api.news.currentNews(this.$route.params.id))
          .data;
        this.currentNews = Object.freeze(data);
        this.currentNewsDate = this.currentNews.published_at;
        this.formatCurrentDate(this.currentNewsDate);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    formatCurrentDate(date) {
      const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      this.currentNewsDate = date.split("-");
      this.currentNewsDate[1] = months[this.currentNewsDate[1] - 1];
      this.currentNewsDate[2] = Number(this.currentNewsDate[2]).toString();
      this.currentNewsDate[0] = [
        this.currentNewsDate[2],
        (this.currentNewsDate[2] = this.currentNewsDate[0]),
      ][0];
      this.currentNewsDate = this.currentNewsDate.join(" ");
    },
  },
};
</script>

<style lang="sass" scoped>
.news-detail
  position: relative
  padding-top: 40px
  padding-bottom: 140px
  min-height: calc(100vh - 531px)
  overflow: hidden
  .back
    margin-bottom: 40px
    a
      color: $light-blue
      display: inline-flex
      align-items: center
      font-weight: 500
      border: 2px solid $light-blue
      border-radius: 9px
      padding: 5px 15px
      transition: .3s ease-in-out
      svg
        margin-right: 5px
        path
          transition: .3s ease-in-out
      &:hover
        background: $light-blue
        color: #000
        svg path
          fill: #000
  &-picture
    margin-bottom: 40px
    img
      border-radius: 9px
      max-width: 100%
  &-date
    font-size: $regular-size
    color: #95A2AA
    margin-bottom: 20px
    @media(max-width: $tablet)
      font-size: 12px
      line-height: 18px
      margin-bottom: 15px
    @media(max-width: $mobile)
      font-size: 10px
      line-height: 16px

  &-title
    font-size: $subtitle-size
    font-weight: 700
    margin-bottom: 40px
    @media(max-width: $tablet)
      font-size: 18px
      line-height: 24px
      margin-bottom: 25px
    @media(max-width: $mobile)
      font-size: 16px
      line-height: 22px

  &-preview
    margin-bottom: 40px
    @media(max-width: $tablet)
      font-size: 14px
      line-height: 20px
      margin-bottom: 25px

.figures
  position: absolute
  width: 1200px
  height: 700px
  bottom: -200px
  right: -100px
</style>
