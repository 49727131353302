<template>
  <section class="requirments">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h2
            class="section-title"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
          >
            <span>Любое устройство</span> <br />
            станет Вашим игровым компьютером
          </h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-5">
          <div class="requirments-sub">Скорость интернета</div>
          <div class="requirments__item">
            <div class="row">
              <div class="col-4">
                <div
                  class="requirments__item-text low"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  3 Мбит/сек
                </div>
              </div>
              <div class="col-8">
                <div
                  class="requirments__item-text low"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  30 FPS, HD (1280x720)
                </div>
              </div>
            </div>
          </div>

          <div class="requirments__item">
            <div class="row">
              <div class="col-4">
                <div
                  class="requirments__item-text medium"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  15 Мбит/сек
                </div>
              </div>
              <div class="col-8">
                <div
                  class="requirments__item-text medium"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  60 FPS, HD+ (1280x720)
                </div>
              </div>
            </div>
          </div>

          <div class="requirments__item">
            <div class="row">
              <div class="col-4">
                <div
                  class="requirments__item-text high"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  20 Мбит/сек
                </div>
              </div>
              <div class="col-8">
                <div
                  class="requirments__item-text high"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  60 FPS, Full HD (1920x1080)
                </div>
              </div>
            </div>
          </div>

          <div class="requirments__item">
            <div class="row">
              <div class="col-4">
                <div
                  class="requirments__item-text ultra"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  35 Мбит/сек
                </div>
              </div>
              <div class="col-8">
                <div
                  class="requirments__item-text ultra"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  120 FPS, 4K (3840x2160)
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col devider d-none d-md-inline-block"></div>
        <div class="col devider d-none d-md-inline-block"></div>

        <div class="col-md-5 mt-4 mt-md-0">
          <div class="requirments-sub">
            Системные требования <br />
            к&nbsp;вашему компьютеру
          </div>
          <div class="requirments__item green">
            <div class="row">
              <div class="col-12">
                <div
                  class="requirments__item-text low"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  1.5 GHz процессор
                </div>
              </div>
            </div>
          </div>

          <div class="requirments__item green">
            <div class="row">
              <div class="col-12">
                <div
                  class="requirments__item-text medium"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  от 1GB оперативки
                </div>
              </div>
            </div>
          </div>

          <div class="requirments__item green">
            <div class="row">
              <div class="col-12">
                <div
                  class="requirments__item-text high"
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  Встроенная\Дискретная видеокарта с поддержкой h264
                </div>
              </div>
            </div>
          </div>

          <div class="requirments__item green">
            <div class="row">
              <div class="col-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="requirments-blick">
      <img
        class="d-none d-md-inline-block"
        src="@/assets/img/main/req-blick.svg"
        alt=""
      />
      <img
        class="d-inline-block d-md-none"
        src="@/assets/img/main/req-blick-m.svg"
        alt=""
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "TheRequirmentsSection",
};
</script>

<style lang="sass" scoped>
.requirments
  padding-top: 83px
  padding-bottom: 151px
  position: relative
  overflow: hidden
  z-index: 0
  @media(max-width: $tablet)
    padding-top: 40px
    padding-bottom: 90px
  .section-title
    margin-bottom: 60px
    span
      color: $light-blue
      @media(max-width: $mobile)
        display: block

  .devider
    @media(max-width: $tablet)
      max-height: 80%

  .devider + .devider
    border-left: 2px solid $green
  &-sub
    font-size: $small-title-size
    font-weight: 800
    height: 104px
    display: flex
    align-items: center
    @media(max-width: $computer) and (min-width: $laptop)
      font-size: 24px
    @media(max-width: $tablet)
      font-size: 20px
    @media(max-width: $mobile)
      font-size: 21px
      justify-content: center

  &__item + &__item
    border-top: 2px solid $blue
  &__item
    padding: 20px 0
    &-text
      font-size: $regular-size
      @media(max-width: $tablet)
        font-size: 12px
    .low
      font-weight: 500
    .medium
      font-weight: 600
    .high
      font-weight: 700
    .ultra
      font-weight: 800

  &-blick
    position: absolute
    pointer-events: none
    bottom: -50px
    left: 0
    right: 0
    margin: auto
    z-index: -1
    img
      width: 100%

  @media(max-width: $mobile)
    padding-bottom: 120px
    .section-title
      position: relative
      &:before
        content: ''
        position: absolute
        width: 370px
        height: 370px
        background: url("@/assets/img/main/req-title-blick.svg") no-repeat
        background-size: cover
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto
        z-index: -1
        pointer-events: none
    .green + .green
      border-top: 2px solid $green
</style>
