<template>
  <div class="figures-parallax">
    <kinesis-element class="parallax1" :strength="strengths[0]">
      <img src="@/assets/img/parallax1.png" alt="" />
    </kinesis-element>

    <kinesis-element class="parallax2" :strength="strengths[1]">
      <img src="@/assets/img/parallax2.png" alt="" />
    </kinesis-element>

    <kinesis-element class="parallax3" :strength="strengths[2]">
      <img src="@/assets/img/parallax3.png" alt="" />
    </kinesis-element>

    <kinesis-element class="parallax4" :strength="strengths[3]">
      <img src="@/assets/img/parallax4.png" alt="" />
    </kinesis-element>
  </div>
</template>

<script>
export default {
  name: "FiguresParallax",
  props: {
    strengths: {
      type: Array,
      default: () => {
        return [50, 30, 60, 41];
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.figures-parallax
  position: absolute
  width: 100%
  left: 0
  top: 0

  .parallax1, .parallax2, .parallax3, .parallax4
    position: absolute
    pointer-events: none
    z-index: -1
    img
      max-width: 100%
  .parallax1
    width: 555px
    height: 490px
    top: 55px
    right: 645px
    @media(max-width: $mobile)
      width: 230px
      height: 200px
      right: 275px
      top: 200px
  .parallax2
    width: 444px
    height: 405px
    right: 53px
    @media(max-width: $mobile)
      width: 210px
      height: 180px
      right: -29px
      top: 100px
  .parallax3
    width: 372px
    height: 404px
    top: -91px
    right: 472px
    @media(max-width: $mobile)
      width: 170px
      height: 179px
      right: 202px
      top: 93px
  .parallax4
    width: 470px
    height: 405px
    top: 231px
    right: 328px
    @media(max-width: $mobile)
      width: 233px
      height: 180px
      right: 27px
      top: 317px
      transform: rotate(78deg) !important
</style>
