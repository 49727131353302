export default function (fetchData) {
  return {
    fullHDQueue() {
      return fetchData.get("cloud-sessions/rest/queues/FULL-HD");
    },
    ultraHDQueue() {
      return fetchData.get("cloud-sessions/rest/queues/ULTRA-HD");
    },
  };
}
