<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      :style="{ maxWidth: width }"
      class="modal-dialog"
      :class="centered ? 'modal-dialog-centered' : ''"
    >
      <div class="modal-content">
        <div class="modal-header d-none d-md-flex">
          <div class="modal-title">
            {{ title }}
          </div>
          <button
            type="button"
            class="btn-close d-none d-md-inline-block"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="modal-close-m d-md-none">
            <a href="#" data-bs-dismiss="modal" aria-label="Close"
              ><img src="@/assets/img/modal-close-m.svg" alt=""
            /></a>
          </div>
        </div>
        <div class="modal-header d-md-none" v-if="getAuth">
          <div class="modal-title">
            {{ title }}
          </div>
          <div class="modal-close-m d-md-none">
            <a href="#" data-bs-dismiss="modal" aria-label="Close"
              ><img src="@/assets/img/modal-close-m.svg" alt=""
            /></a>
          </div>
        </div>
        <div class="modal-header-m d-md-none" v-else>
          <div class="modal-logo">
            <img src="@/assets/img/login-logo-m.png" alt="" />
          </div>
          <div class="modal-title">
            {{ title }}
          </div>
          <div class="modal-close-m d-md-none">
            <a href="#" data-bs-dismiss="modal" aria-label="Close"
              ><img src="@/assets/img/modal-close-m.svg" alt=""
            /></a>
          </div>
        </div>
        <div class="modal-body">
          <slot></slot>
          <div class="modal-body-blick d-md-none" v-if="!getAuth">
            <img src="@/assets/img/modal-blick.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "modalWindow",
  props: {
    id: String,
    title: String,
    centered: Boolean,
    width: String,
  },
  computed: {
    ...mapGetters({ getAuth: "user/getAuth" }),
  },
};
</script>

<style lang="sass" scoped>
.modal
  &-dialog
    max-width: 630px
    @media(max-width: $tablet)
      max-width: 548px
    @media(max-width: $mobile)
      margin: 0
      max-width: 100%
  &-content
    background: #142244
    border-radius: 9px
    @media(max-width: $mobile)
      background: #0C0D0D
      min-height: 100vh
      border-radius: 0
      border: 0
  &-close-m
    position: absolute
    top: 15px
    left: 15px
    z-index: 10
  &-header
    position: relative
    display: flex
    align-items: center
    justify-content: center
    height: 113px
    background: url("@/assets/img/modal-header-blick.svg") no-repeat center center
    background-size: cover
    border-bottom: 0
    @media(max-width: $mobile)
      align-items: flex-end
      min-height: 250px
      border-radius: 0
    &:after
      content: ''
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      background: url("@/assets/img/modal-header-bg.png") no-repeat center center
      background-size: cover
      z-index: 0
      @media(max-width: $mobile)
        background: url("@/assets/img/account/background.png") no-repeat center center
        background-size: cover
    .btn-close
      position: absolute
      width: 28px
      height: 28px
      padding: 0
      margin: 0
      background: url("@/assets/img/modal-close.svg") no-repeat center center, $blue
      background-size: 18px 18px
      border-radius: 50%
      opacity: 1
      right: -10px
      top: -10px
      will-change: opacity
      transition: opacity .3s
      z-index: 10
      &:hover, &:focus, &:active
        outline: none
        border-color: unset
        box-shadow: unset
        opacity: .8
        will-change: opacity
        transition: opacity .3s
  &-header-m
    display: flex
    position: relative
    flex-direction: column
    justify-content: center
    align-items: center
    .modal-logo
      padding-top: 40px
      padding-bottom: 30px
      img
        width: 260px
    .modal-title
      margin-bottom: 0
  &-title
    font-size: $small-title-size
    font-weight: 800
    display: inline-block
    position: relative
    z-index: 5
    @media(max-width: $mobile)
      font-size: 21px
      font-weight: 600
      margin-bottom: 25px
  &-body
    position: relative
    padding: 40px 0
    text-align: center
    z-index: 5
    @media(max-width: $mobile)
      padding: 30px 0
    &-blick
      position: absolute
      bottom: 0
      left: 0
      right: 0
      margin: auto
      z-index: -1
      img
        max-width: 100%
        width: 100%
.modal.fade
  &:not(.show) .modal-dialog
    transform: scale(.8)
  .modal-dialog
    transform: scale(1)
</style>
