<template>
  <div class="error">
    <p class="error-message">
      <slot></slot>
    </p>
  </div>
</template>

<script></script>

<style lang="sass" scoped>
.error
  &-message
    background: $error
    padding: 20px
    border-radius: 9px
    text-align: center
    color: $menu-background
    @media(max-width: $mobile)
      font-size: 12px
</style>
