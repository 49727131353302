export default {
  namespaced: true,
  state: {
    queues: {
      fullHD: 0,
      ultraHD: 0,
    },
  },
  getters: {
    getQueue(state) {
      return state.queues;
    },
  },
  mutations: {
    CHANGE_FULLHD_QUEUE(state, payload) {
      state.queues.fullHD = payload;
    },

    CHANGE_ULTRAHD_QUEUE(state, payload) {
      state.queues.ultraHD = payload;
    },
  },
  actions: {
    async getFullHDQueue(context, payload) {
      const data = await payload.queue.fullHDQueue();
      context.commit("CHANGE_FULLHD_QUEUE", data.data);
    },
    async getUltraHDQueue(context, payload) {
      const data = await payload.queue.ultraHDQueue();
      context.commit("CHANGE_ULTRAHD_QUEUE", data.data);
    },
  },
};
