<template>
  <section class="news-list">
    <div class="container">
      <div class="row" v-if="!noNews">
        <div class="col-12" v-for="(news, index) in newsList" :key="news.id">
          <div class="news">
            <div class="news-date">
              {{ news.published_at }}
            </div>
            <div class="news-title">
              <router-link :to="'/news/' + news.id">{{
                news.headline
              }}</router-link>
            </div>
            <div class="news-picture" v-if="index === 0 && news.picture">
              <img :src="'https://' + apiUrl + news.picture" alt="" />
            </div>
            <div
              class="news-preview"
              v-html="news.content.substr(0, 255) + '...'"
            ></div>

            <div class="news-readmore">
              <router-link :to="'/news/' + news.id">Читать дальше</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 text-center" v-else>
        <div class="news__not-found">
          <div class="news__not-found-img">
            <img src="@/assets/img/news/no-news.png" alt="Нет новостей" />
          </div>
          <div class="news__not-found-title">
            На данный момент тут пусто <span>:(</span>
          </div>
          <div class="news__not-found-text">Возвращайтесь поздней</div>
        </div>
      </div>
    </div>
    <div class="news-list-blick" v-if="Object.keys(newsList).length > 3">
      <img src="@/assets/img/news/blick.svg" alt="" />
    </div>
  </section>
</template>

<script>
export default {
  name: "NewsList",
  data() {
    return {
      newsList: {},
      apiUrl: process.env.VUE_APP_API_URL,
      noNews: true,
    };
  },
  mounted() {
    this.getFullNews();
  },
  methods: {
    async getFullNews() {
      const data = await this.$api.news.allNews();
      this.newsList = data.data.results;
      Object.keys(this.newsList).length > 0
        ? (this.noNews = false)
        : (this.noNews = true);
      this.newsList = this.newsList.map((news) => {
        return {
          ...news,
          published_at: this.formatCurrentDate(news.published_at),
        };
      });
    },
    formatCurrentDate(date) {
      const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      date = date.split("-");
      date[1] = months[date[1] - 1];
      date[2] = Number(date[2]).toString();
      date[0] = [date[2], (date[2] = date[0])][0];
      date = date.join(" ");

      return date;
    },
  },
};
</script>

<style lang="sass" scoped>
.news-list
  padding-top: 15px
  &-blick
    position: absolute
    top: 0
    right: 0
    pointer-events: none
    z-index: -1
    img
      @media(max-width: $tablet)
        max-height: 100vh

  .news
    margin-bottom: 85px
    @media(max-width: $tablet)
      margin-bottom: 65px
    @media(max-width: $mobile)
      margin-bottom: 45px
    &-date
      font-size: $regular-size
      color: #95A2AA
      margin-bottom: 20px
      @media(max-width: $tablet)
        font-size: 12px
        line-height: 18px
        margin-bottom: 15px
      @media(max-width: $mobile)
        font-size: 10px
        line-height: 16px

    &-title
      font-size: $subtitle-size
      font-weight: 700
      margin-bottom: 40px
      @media(max-width: $tablet)
        font-size: 18px
        line-height: 24px
        margin-bottom: 25px
      @media(max-width: $mobile)
        font-size: 16px
        line-height: 22px

    &-picture
      margin-bottom: 40px
      img
        max-width: 50%
        width: 50%
        border-radius: 9px

    &-preview
      margin-bottom: 40px
      @media(max-width: $tablet)
        font-size: 14px
        line-height: 20px
        margin-bottom: 25px

    &-readmore
      font-weight: 500
      a
        &:hover
          text-decoration: underline

    &__not-found
      padding: 0 0 100px 0
      @media(max-width: $tablet)
        padding: 0 0 50px 0
      &-img
        img
          max-width: 450px
          @media(max-width: $tablet)
            max-width: 50%
          @media(max-width: $mobile)
            max-width: 100%
      &-title
        font-size: $small-title-size
        font-weight: 700
        margin-bottom: 20px
        @media(max-width: $tablet)
          font-size: 22px
        span
          color: $green
      &-text
        font-size: $subtitle-size
        @media(max-width: $tablet)
          font-size: 16px
</style>
