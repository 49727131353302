<template>
  <section class="not-found">
    <div class="container not-found__block">
      <div class="row">
        <div class="col-12 text-center">
          <div class="not-found-title">404</div>
          <div class="not-found-text">
            <b>Упс!</b> Такой страницы не существует <span>:(</span>
          </div>
          <div class="not-found-btn">
            <a @click="$router.go(-1)">Назад</a>
          </div>
        </div>
      </div>
    </div>
    <div class="not-found-blick">
      <img
        class="d-none d-md-block"
        src="@/assets/img/main/req-blick.svg"
        alt=""
      />
      <img
        class="d-block d-md-none"
        src="@/assets/img/main/req-blick-m.svg"
        alt=""
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFoundView",
};
</script>

<style lang="sass" scoped>
.not-found
  margin-top: 45px
  margin-bottom: 75px
  position: relative
  &-blick
    position: absolute
    bottom: -75px
    left: 0
    right: 0
    margin: auto
    z-index: -1
    img
      max-width: 100%
      width: 100%
  &__block
    padding: 165px 15px 215px
    background: url("@/assets/img/404/bg.png") no-repeat center center
    background-size: cover
    overflow: visible
    @media(max-width: $mobile)
      padding: 100px 15px 150px
      background: url("@/assets/img/404/bg-m.png") no-repeat center center
      background-size: contain
  &-title
    font-size: 207px
    line-height: 213px
    font-weight: 800
    @media(max-width: $tablet)
      font-size: 150px
      line-height: 156px
    @media(max-width: $mobile)
      font-size: 100px
      line-height: 106px
  &-text
    font-size: $subtitle-size
    margin-bottom: 40px
    @media(max-width: $tablet)
      font-size: 20px
    @media(max-width: $mobile)
      font-size: 16px
    span
      color: $green
  &-btn
    a
      border: 2px solid $light-blue
      border-radius: 9px
      padding: 8px 0
      display: inline-block
      width: 298px
      color: $light-blue !important
      font-size: $subtitle-size
      font-weight: 600
      cursor: pointer
      transition: .3s ease-in-out
      @media(max-width: $tablet)
        font-size: 20px
      @media(max-width: $mobile)
        font-size: 16px
        max-width: 375px
        width: 100%
      &:hover
        color: #000 !important
        background: $light-blue
        transition: .3s ease-in-out
</style>
