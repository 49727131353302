<template>
  <section class="versus">
    <div class="container">
      <div class="row position-relative">
        <div class="versus-img d-none d-md-inline-block" data-aos="zoom-in">
          <img src="@/assets/img/main/versus.png" alt="" />
        </div>
        <div class="col-md-6">
          <div class="versus__side normal">
            <div
              class="versus__side-img d-none d-md-flex"
              data-aos="fade-right"
            >
              <img src="@/assets/img/main/low.png" alt="Обычный ПК" />
              <div class="versus__side-img-blick">
                <img
                  src="@/assets/img/main/versus-normal-light-img.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="versus__side-divider d-none d-md-flex">
              <div></div>
            </div>

            <div class="versus__side__text-block text-center position-relative">
              <div class="versus__side-bg">
                <img src="@/assets/img/main/versus-normal-light.svg" alt="" />
              </div>
              <div
                class="d-inline-block text-md-start text-center"
                data-aos="fade-down"
              >
                <div class="versus__side-title">Обычный игровой ПК</div>

                <div class="versus__side-spec">
                  <span>Процессор</span> Intel Core i9 8 ядер
                </div>

                <div class="versus__side-spec">
                  <span>ОП</span> DDR4 16 ГБ 3600Mhz
                </div>

                <div class="versus__side-spec">
                  <span>Диск</span> 300 ГБ NVME
                </div>

                <div class="versus__side-spec">
                  <span>Видеокарта</span> RTX 3080Ti
                </div>

                <div class="versus__side-spec">
                  <span>Стоимость от 300 000₽</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 d-inline-block d-md-none">
          <div class="versus-img_mobile">
            <img src="@/assets/img/main/versus.png" alt="" />
          </div>
        </div>

        <div class="col-md-6">
          <div class="versus__side cloud">
            <div class="versus__side-img d-none d-md-flex" data-aos="fade-left">
              <img src="@/assets/img/main/high.png" alt="Облачный ПК Gatoga" />
              <div class="versus__side-img-blick">
                <img
                  src="@/assets/img/main/versus-cloud-light-img.svg"
                  alt=""
                />
              </div>
            </div>

            <div class="versus__side-divider d-none d-md-flex">
              <div></div>
            </div>

            <div class="versus__side__text-block text-center position-relative">
              <div class="versus__side-bg">
                <img src="@/assets/img/main/versus-cloud-light.svg" alt="" />
              </div>
              <div
                class="d-inline-block text-md-start text-center"
                data-aos="fade-down"
              >
                <div class="versus__side-title">Облачный ПК Gatoga</div>

                <div class="versus__side-spec">
                  <span>Процессор</span> Intel Core i9 8 ядер
                </div>

                <div class="versus__side-spec">
                  <span>ОП</span> DDR4 32 ГБ 3600Mhz
                </div>

                <div class="versus__side-spec">
                  <span>Диск</span> 300 ГБ NVME
                </div>

                <div class="versus__side-spec">
                  <span>Видеокарта</span> RTX 3080Ti
                </div>

                <div class="versus__side-spec">
                  <span class="green">Стоимость от 42₽* в час</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 d-inline-block d-md-none">
          <div class="versus__mobile-block">
            <div class="versus__mobile-block_left">
              <img src="@/assets/img/main/low-m.png" alt="Обычный ПК" />
            </div>
            <div class="versus__mobile-block_right">
              <img src="@/assets/img/main/high-m.png" alt="Облако GATOGA" />
            </div>
            <div class="versus__mobile-block_blick">
              <img src="@/assets/img/main/versus-blick-m.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TheVersusSection",
};
</script>

<style lang="sass" scoped>
.versus
  padding: 160px 0 93px
  position: relative
  &-img
    display: inline-block
    width: 266px
    height: 220px
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: auto
    z-index: 10
    @media(max-width: $computer) and (min-width: $laptop)
      width: 200px
      height: 145px
      top: -80px
    @media(max-width: $tablet)
      width: 172px
      height: 145px
      top: -50px
    img
      max-width: 100%
  &__side
    &:hover
      .versus__side-img-blick
        opacity: 1
        @media(max-width: $mobile)
          opacity: 0
      .versus__side-bg
        opacity: 1
        @media(max-width: $mobile)
          opacity: 0
    &-bg
      opacity: 0
      position: absolute
      top: 0
      left: -90px
      z-index: -1
      transition: .3s
      @media(max-width: $tablet)
        max-width: 515px
        left: -20px
        img
          max-width: 100%
    &-img
      height: 710px
      max-height: 710px
      position: relative
      display: flex
      align-items: flex-end
      background: $black
      z-index: 5
      @media(max-width: $computer) and (min-width: $laptop)
        height: 500px
      @media(max-width: $tablet)
        height: 401px
      &-blick
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        margin: auto
        opacity: 0
        z-index: -1
        transition: .3s
        img
          width: auto
          height: auto
      img
        max-height: 100%

    &-divider
      display: flex
      div
        width: 95%
        height: 2px

    &__text-block
      padding-top: 45px
      @media(max-width: $tablet)
        padding-top: 30px

    &-title
      font-size: $subtitle-size
      font-weight: 700
      margin-bottom: 20px
      @media(max-width: $tablet)
        font-size: 20px
        margin-bottom: 12px

    &-spec
      @media(max-width: $tablet)
        font-size: 12px
      & + &
        margin-top: 10px
      span
        font-weight: 700
        &.green
          color: $green

  .normal
    .versus__side-img
      direction: rtl
      @media(max-width: $computer) and (min-width: $laptop)
        right: 30px
      @media(max-width: $tablet)
        img
          max-width: 386px
    .versus__side-divider
      div
        background: $blue

  .cloud
    .versus__side-img
      &-blick
        left: 35px
      img
        height: 665px
        position: relative
        right: 50px
        @media(max-width: $computer) and (min-width: $laptop)
          right: 10px
        @media(max-width: $tablet)
          max-width: 407px
          height: auto
          right: 20px
    .versus__side-divider
      justify-content: flex-end
      div
        background: $green

  @media(max-width: $mobile)
    &__side__text-block
      padding-top: 0
    &__side-spec
      margin-bottom: 10px
    &-img_mobile
      text-align: center
      padding: 15px 0
      img
        max-width: 100%
        width: 70%
    &__mobile-block
      display: flex
      align-items: flex-end
      justify-content: center
      border-bottom: 2px solid $green
      position: relative
      &_left, &_right
        max-width: 50%
        width: 50%
        img
          position: relative
          left: -15px
          max-width: 120%
      &_right
        text-align: right
        img
          left: unset
          right: 20px
      &_blick
        position: absolute
        bottom: 0
        z-index: -1
        pointer-events: none
        img
          width: 100%
</style>
