<template>
  <form class="promocode-form mx-auto" @submit.prevent="sendPromocode">
    <form-error-display v-if="error">{{ error }}</form-error-display>
    <div class="promocode-form-text">Введите промокод</div>
    <input
      id="promocode"
      class="form-control promocode-form-input"
      v-model="promocode"
      required
    />
    <button class="btn-blue btn-submit mt-4" type="submit">Применить</button>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import FormErrorDisplay from "./FormErrorDisplay";

export default {
  name: "FormPromocode",
  props: {
    modal: Object,
  },
  components: {
    FormErrorDisplay,
  },
  data() {
    return {
      promocode: "",
      error: "",
    };
  },
  mounted() {
    let promocodeModal = document.getElementById("promocode");
    promocodeModal.addEventListener("hidden.bs.modal", () => {
      this.error = "";
      this.promocode = "";
    });
  },
  methods: {
    ...mapActions({ getProfile: "user/getProfile" }),
    resetForm() {
      this.promocode = "";
    },
    async sendPromocode() {
      try {
        // eslint-disable-next-line no-unused-vars
        const data = (
          await this.$api.promocode.sendPromocode({
            name: this.promocode,
          })
        ).data;
        this.getProfile(this.$api);
        this.modal.hide();
        alert("Код успешно применен");
        this.$router.go();
        this.resetForm();
        this.error = "";
      } catch (error) {
        let errorMessage = error.response.data.text;
        console.log(error.response);
        switch (error.response.status) {
          case 400:
            switch (error.response.data.code) {
              case "only_for_new_gamers":
                this.error = errorMessage;
                break;
              case "only_for_other_gamers":
                this.error = errorMessage;
                break;
              case "not_available_for_use":
                this.error = errorMessage;
                break;
              case "code_already_used":
                this.error = errorMessage;
                break;
            }
            break;
          case 429:
            this.error = "Слишком много запросов. Повторите попытку поздней";
            break;
          default:
            this.error = "Неизвестная ошибка";
            break;
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.promocode-form
  max-width: 360px
  text-align: left
  @media(max-width: $tablet)
    max-width: 311px
  &-text
    margin-bottom: 5px
  &-input
    background: #1A316C
    border: 0
    border-radius: 9px
    color: #fff
    &:hover, &:active, &:focus
      background: #1A316C
      color: #fff !important
</style>
