<template>
  <div class="expansion-item" :class="classes">
    <div class="expansion-item__container">
      <div
        class="expansion-item__label"
        :disable="disable"
        @click="__onHeaderClick"
      >
        <div
          class="expansion-item__additional-label"
          v-if="additionalLabel || $slots.additionalLabel"
        >
          {{ additionalLabel }} <slot name="additionalLabel" />
        </div>
        <div class="expansion-item__label-container">
          <div class="expansion-item__label-text">
            {{ label }} <slot name="label" />
          </div>
          <div class="expansion-item__label-ico">
            <img src="@/assets/img/main/arrow-closed.svg" alt="" />
          </div>
        </div>
      </div>
      <transition v-on:enter="enter" v-on:leave="leave" :duration="duration">
        <div v-if="showing" class="expansion-item__content">
          <div class="expansion-item__content-text">{{ content }} <slot /></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
const qListeners = getPropCacheMixin("$listeners", "qListeners");
import mitt from "mitt";

const emitter = mitt();

export default {
  name: "expansion-item",
  props: {
    defaultOpened: Boolean,
    label: {
      type: String,
      default: "",
    },
    additionalLabel: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    disable: Boolean,
    group: String,
    duration: {
      type: Number,
      default: 300,
    },
  },
  mixins: [qListeners],
  data() {
    return {
      showing: this.value !== void 0 ? this.value : this.defaultOpened,
    };
  },
  watch: {
    showing(val) {
      val === true &&
        this.group !== void 0 &&
        emitter.emit("expansionItem:close", this);
    },

    group(newVal, oldVal) {
      if (newVal !== void 0 && oldVal === void 0) {
        emitter.on("expansionItem:close", this.__eventHandler);
      } else if (newVal === void 0 && oldVal !== void 0) {
        emitter.off("expansionItem:close", this.__eventHandler);
      }
    },

    value(val) {
      this.__processModelChange(val);
    },
  },
  computed: {
    classes() {
      return `expansion-item_${
        this.showing === true ? "expanded" : "collapsed"
      }`;
    },
  },
  methods: {
    __begin(el, height, done) {
      el.style.overflowY = "hidden";
      if (height !== void 0) {
        el.style.height = `${height}px`;
      }
      el.style.transition = `height ${this.duration}ms cubic-bezier(.25, .8, .50, 1)`;

      this.animating = true;
      this.done = done;
    },

    __end(el, event) {
      el.style.overflowY = null;
      el.style.height = null;
      el.style.transition = null;
      this.__cleanup();
      event !== this.lastEvent && emitter.emit(event);
    },

    __cleanup() {
      this.done && this.done();
      this.done = null;
      this.animating = false;

      clearTimeout(this.timer);
      clearTimeout(this.timerFallback);
      this.el !== void 0 &&
        this.el.removeEventListener("transitionend", this.animListener);
      this.animListener = null;
    },

    enter(el, done) {
      let pos = 0;
      this.el = el;

      if (this.animating === true) {
        pos = el.offsetHeight === el.scrollHeight ? 0 : void 0;
      } else {
        this.lastEvent = "hide";
      }

      this.__begin(el, pos, done);

      this.timer = setTimeout(() => {
        el.style.height = `${el.scrollHeight}px`;
        this.animListener = (ev) => {
          if (Object(ev) !== ev || ev.target === el) {
            this.__end(el, "show");
          }
        };
        el.addEventListener("transitionend", this.animListener);
        this.timerFallback = setTimeout(this.animListener, this.duration * 1.1);
      }, 100);
    },

    leave(el, done) {
      let pos;
      this.el = el;

      if (this.animating === true) {
        this.__cleanup();
      } else {
        this.lastEvent = "show";
        pos = el.scrollHeight;
      }

      this.__begin(el, pos, done);

      this.timer = setTimeout(() => {
        el.style.height = 0;
        this.animListener = (ev) => {
          if (Object(ev) !== ev || ev.target === el) {
            this.__end(el, "hide");
          }
        };
        el.addEventListener("transitionend", this.animListener);
        this.timerFallback = setTimeout(this.animListener, this.duration * 1.1);
      }, 100);
    },

    __prepareTick() {
      if (this.__tickFn !== void 0) {
        const fn = this.__tickFn;
        this.$nextTick(() => {
          if (this.__tickFn === fn) {
            this.__tickFn();
            this.__tickFn = void 0;
          }
        });
      }
    },

    __clearTick() {
      this.__tickFn = void 0;
    },

    toggle(evt) {
      this[this.showing === true ? "hide" : "show"](evt);
    },

    show(evt) {
      if (
        this.disable === true ||
        (this.__showCondition !== void 0 && this.__showCondition(evt) !== true)
      ) {
        return;
      }

      if (qListeners.input !== void 0) {
        this.$emit("input", true);
        this.payload = evt;
        this.$nextTick(() => {
          if (this.payload === evt) {
            this.payload = void 0;
          }
        });
      }

      if (this.value === void 0 || qListeners.input === void 0) {
        this.__processShow(evt);
      }
    },

    __processShow(evt) {
      if (this.showing === true) {
        return;
      }

      this.__preparePortal !== void 0 && this.__preparePortal();

      this.showing = true;

      emitter.emit("before-show", evt);

      emitter.emit("show", evt);
    },

    hide(evt) {
      if (this.disable === true) {
        return;
      }

      if (qListeners.input !== void 0) {
        emitter.emit("input", false);
        this.payload = evt;
        this.$nextTick(() => {
          if (this.payload === evt) {
            this.payload = void 0;
          }
        });
      }
      if (this.value === void 0 || qListeners.input === void 0) {
        this.__processHide(evt);
      }
    },

    __processHide(evt) {
      if (this.showing === false) {
        return;
      }

      this.showing = false;

      emitter.emit("before-hide", evt);

      if (this.__hide !== void 0) {
        this.__clearTick();
        this.__hide(evt);
        this.__prepareTick();
      } else {
        emitter.emit("hide", evt);
      }
    },

    __processModelChange(val) {
      if (this.disable === true && val === true) {
        qListeners.input !== void 0 && emitter.emit("input", false);
      } else if ((val === true) !== this.showing) {
        this[`__process${val === true ? "Show" : "Hide"}`](this.payload);
      }
    },

    __onHeaderClick(e) {
      this.hasRouterLink !== true && this.toggle(e);
      emitter.emit("click", e);
    },

    __eventHandler(comp) {
      this !== comp && this.group === comp.group && this.hide();
    },
  },
  created() {
    this.group !== void 0 &&
      emitter.on("expansionItem:close", this.__eventHandler);
  },

  beforeUnmount() {
    this.__tickFn = void 0;
    this.animating && this.__cleanup();
    this.group !== void 0 &&
      emitter.off("expansionItem:close", this.__eventHandler);
  },
};

function getPropCacheMixin(propName, proxyPropName) {
  return {
    data() {
      const target = {};
      const source = this[propName];

      for (const prop in source) {
        target[prop] = source[prop];
      }

      return { [proxyPropName]: target };
    },

    watch: {
      [propName](newObj, oldObj) {
        const target = this[proxyPropName];

        if (oldObj !== void 0) {
          for (const prop in oldObj) {
            if (newObj[prop] === void 0) {
              this.$delete(target, prop);
            }
          }
        }

        for (const prop in newObj) {
          if (target[prop] !== newObj[prop]) {
            this.$set(target, prop, newObj[prop]);
          }
        }
      },
    },
  };
}
</script>

<style lang="sass" scoped>
.expansion-item
  border-radius: 9px
  &_collapsed
    &:hover
      outline: 2px solid $blue
  &__label
    display: flex
    flex-wrap: wrap
    padding: 30px 0 30px
    cursor: pointer
    &:hover &-ico .icon
      border-radius: 20px
      transition: .3s
    &:hover &-ico .icon::v-deep svg path
      fill: $green
      transition: .3s
    &-container
      display: flex
      width: 100%
      justify-content: space-between
      align-items: center
      padding: 0 40px
    &-text
      font-size: $subtitle-size
      font-weight: 600
      @media(max-width: $mobile)
        font-size: 16px
        padding-right: 15px
    &-ico
      display: flex
      justify-self: flex-end
      align-items: center
    &-ico img
      transition: .3s
    .expansion-item__additional-label
      color: $light-blue
      flex: 100%
      margin-bottom: 8px

  &__content-text
    padding: 0 40px 30px
    @media(max-width: $mobile)
      font-size: 14px

  &_expanded
    background: linear-gradient(180deg, #2750B9 1.19%, #0C0D0D 98.81%)
    .expansion-item__label
      &-ico img
        transform: rotate(180deg)
        transition: .3s ease-in-out
  @media(max-width: 768px)
    &_expanded
      padding-bottom: 25px
    &__label
      &-ico .icon::v-deep
        width: 16px
        height: 16px
        overflow: hidden
      &:hover &-ico .icon
        background: transparent
      .expansion-item__additional-label
        color: $light-blue
</style>
