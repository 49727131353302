<template>
  <section class="footer">
    <div class="container">
      <div class="row footer-divider mb-50"></div>
      <div class="row footer-block text-center text-md-start">
        <div class="col-md">
          <div class="footer__logo">
            <img src="../assets/img/logo.svg" alt="Gatoga Logo" />
            <span class="beta">beta</span>
          </div>
          <div class="footer-slogan">
            Теперь мощный компьютер <br />
            может позволить себе каждый.
          </div>
        </div>

        <div class="col-md-auto">
          <div class="footer__item">
            <div class="footer__item-title">Карта сайта</div>

            <div class="footer__item-link">
              <router-link to="/">Главная</router-link>
            </div>
            <div class="footer__item-link">
              <router-link to="/download/">Скачать</router-link>
            </div>
            <div class="footer__item-link">
              <a href="/#how">Как это работает?</a>
            </div>
            <div class="footer__item-link">
              <router-link to="/news">Новости</router-link>
            </div>
            <div class="footer__item-link">
              <router-link to="/faq/">Центр помощи</router-link>
            </div>
          </div>
        </div>

        <div class="col-md-auto">
          <div class="footer__item">
            <div class="footer__item-title">Контакты</div>

            <div class="footer__item-link">
              <a href="https://vk.com/im?sel=-190214203" target="_blank"
                >Техподдержка</a
              >
            </div>
            <div class="footer__item-link">
              <router-link to="/agreement"
                >Юридическая <br class="d-none d-md-inline-block" />
                информация</router-link
              >
            </div>
          </div>
        </div>

        <div class="col-md-auto">
          <div class="footer__item">
            <div class="footer__item-title">Наши соцсети</div>

            <div class="footer__item-soc">
              <div class="row justify-content-between g-0">
                <div class="col-auto">
                  <a target="_blank" href="https://vk.com/gatoga"
                    ><img src="@/assets/img/vk-soc.svg" alt=""
                  /></a>
                </div>
                <div class="col-auto">
                  <a target="_blank" href="https://t.me/gatoga_cloud"
                    ><img src="@/assets/img/tg.svg" alt=""
                  /></a>
                </div>
                <div class="col-auto">
                  <a
                    target="_blank"
                    href="https://www.youtube.com/results?search_query=GATOGA"
                    ><img src="@/assets/img/youtube.svg" alt=""
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row footer-divider"></div>
      <div class="row">
        <div class="col-12 text-center">
          <div class="footer-copyright">
            © 2020-{{ currentYear }} <a href="./">Gatoga.ru</a>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-blick">
      <img
        class="d-none d-md-inline-block"
        src="@/assets/img/footer-blick.svg"
        alt=""
      />
      <img
        class="d-inline-block d-md-none"
        src="@/assets/img/footer-blick-m.svg"
        alt=""
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterSection",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="sass" scoped>
.footer
  position: relative
  &__logo
    display: flex
    align-items: flex-end
    @media(max-width: $mobile)
      justify-content: center
    img
      width: 225px
      margin-right: 15px
      @media(max-width: $tablet)
        width: 140px
        margin-right: 10px
      @media(max-width: $mobile)
        width: 118px
        margin-right: 5px
    .beta
      color: $light-blue
      font-size: $regular-size
      padding-bottom: 8px
      @media(max-width: $tablet)
        font-size: 12px
        padding-bottom: 5px
      @media(max-width: $mobile)
        font-size: 9px
        padding-bottom: 5px
  &-blick
    position: absolute
    bottom: 0
    left: 0
    z-index: -1
    @media(max-width: $tablet)
      left: -380px
    @media(max-width: $mobile)
      left: 0
      right: 0
      margin: auto
      img
        max-width: 100%
        width: 100%
  &-divider
    height: 2px
    background: $blue
  .mb-50
    margin-bottom: 50px
  .footer-block
    margin-bottom: 130px
    @media(max-width: $mobile)
      margin-bottom: 30px
  &-slogan
    margin-top: 40px
    font-size: $regular-size
    font-weight: 400
    @media(max-width: $tablet)
      font-size: 12px
      margin-top: 25px
    @media(max-width: $mobile)
      margin-top: 15px
      font-size: 14px
  &__item
    margin-left: 70px
    @media(max-width: $tablet)
      margin-left: 0
      margin-right: 40px
    @media(max-width: $mobile)
      margin-left: 0
      margin-right: 0
    &-title
      font-size: $regular-size
      font-weight: 700
      color: $light-blue
      margin-bottom: 20px
      @media(max-width: $tablet)
        font-size: 12px
        margin-top: 10px
      @media(max-width: $mobile)
        font-size: 14px
        margin-top: 25px
    &-link
      a
        font-size: $regular-size
        font-weight: 400
        color: $gray
        display: inline-block
        margin-bottom: 10px
        transition: color .3s
        @media(max-width: $tablet)
          font-size: 12px
        @media(max-width: $mobile)
          font-size: 14px
        &:hover
          color: #fff
          transition: color .3s

    &-soc
      margin-top: 10px
      @media(max-width: $mobile)
        max-width: 170px
        margin: auto
      a
        display: inline-flex
        align-items: center
        justify-content: center
        border-radius: 6px
        width: 30px
        height: 30px
        transition: .3s
        &:hover
          background: rgba($light-blue, .2)
          transition: .3s

  &-copyright
    padding: 25px 0
    color: $gray
    @media(max-width: $tablet)
      font-size: 12px
    @media(max-width: $mobile)
      font-size: 14px
    a
      color: $gray
      transition: color .3s
      &:hover
        color: #fff
        transition: color .3s
</style>
