<template>
  <form
    class="auth-form mx-auto"
    v-if="!isCodeSend"
    @submit.prevent="callToUser"
  >
    <form-error-display v-if="error">{{ error }}</form-error-display>
    <div class="auth-form-text">Введите ваш номер телефона</div>
    <vue-tel-input
      class="auth-form-input"
      v-model="user_phone"
      ref="input"
    ></vue-tel-input>
    <button class="btn-blue btn-submit mt-4" type="submit">Далее</button>

    <div class="text-center">
      <a
        class="auth-form-link"
        href="https://vk.com/im?sel=-190214203"
        target="_blank"
        >Моей страны нет в списке</a
      >
    </div>
  </form>

  <form class="auth-form mx-auto" v-if="isCodeSend" @submit.prevent="login">
    <div class="auth-form-text auth-form-code text-center">
      Подтвердите номер телефона:<br />Совершите вызов с вашего номера на номер
      <a class="phoneLink" :href="'tel:+' + service_phone"
        >+{{ formatedServicePhone }}</a
      ><br />Звонок бесплатный, трубка будет сразу сброшена.
    </div>
    <form-error-display v-if="error">{{ error }}</form-error-display>
    <div class="text-center mx-auto">
      <a
        v-if="isMobile()"
        :href="'tel:+' + service_phone"
        class="btn-blue btn-submit mt-2 mb-2 authCallButton"
      >
        Позвонить +{{ formatedServicePhone }}
      </a>
    </div>
    <div class="text-center">
      <vue-qrcode
        :value="'tel:+' + service_phone"
        margin="2"
        scale="8"
        quality="1"
      />
    </div>
    <span class="timer text-center d-block mt-1" v-if="currentTime > 0">
      {{ formatedCurrentTime }}</span
    >
    <button class="btn-blue btn-submit mt-2" @click.prevent="callToUser" v-else>
      Попробовать еще раз
    </button>
    <div class="text-center">
      <a
        class="auth-form-link"
        href="https://vk.com/im?sel=-190214203"
        target="_blank"
        >Не могу позвонить</a
      >
    </div>
  </form>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import FormErrorDisplay from "./FormErrorDisplay";
import VueQrcode from "vue-qrcode";

export default {
  name: "FormAuth",
  props: {
    modal: Object,
  },
  emits: ["currentStartEvent"],
  components: {
    FormErrorDisplay,
    VueQrcode,
  },
  data() {
    return {
      isCodeSend: false,
      user_phone: "",
      service_phone: "",
      formatedServicePhone: "",
      countryCode: "",
      error: "",
      currentTime: 120,
      formatedCurrentTime: "",
      timer: null,
      authCycle: false,
    };
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer();
        this.formatedCurrentTime = "Истекло время, попробуйте ещё раз";
      }
    },
  },
  mounted() {
    let authModal = document.getElementById("auth");
    authModal.addEventListener("hidden.bs.modal", () => {
      this.isCodeSend = false;
      this.user_phone = "";
      this.service_phone = "";
      this.formatedServicePhone = "";
      this.authCycle = false;
      this.stopTimer();
      this.formatedCurrentTime = "";
      this.currentTime = 120;
    });
  },
  methods: {
    ...mapMutations({ setToken: "user/SET_TOKEN", setAuth: "user/SET_AUTH" }),
    ...mapActions({ getProfile: "user/getProfile" }),
    getCountryCode() {
      this.countryCode = this.$refs.input.phoneObject.countryCallingCode;
    },
    async callToUser() {
      try {
        if (!this.isCodeSend) this.getCountryCode();
        // eslint-disable-next-line no-unused-vars
        const data = (
          await this.$api.auth.callToUser({
            phone_number: this.countryCode + this.user_phone,
          })
        ).data;
        this.service_phone = data.service_phone;
        this.formatedServicePhone = this.service_phone.replace(
          /7(\d{3})(\d{3})(\d{2})(\d{2})/,
          "7 $1 $2 $3 $4"
        );
        this.isCodeSend = true;
        this.startTimer();
        this.startAuthChecker();
      } catch (error) {
        switch (error.response.status) {
          case 429:
            this.error =
              "Превышено допустимое количество запросов на сервер. Повторите попытку позже";
            break;
        }
      }
    },
    async login() {},
    startTimer() {
      this.currentTime = 120;
      this.timer = setInterval(
        function () {
          this.currentTime--;
          this.formatedCurrentTime = new Date(this.currentTime * 1000)
            .toISOString()
            .substr(14, 5);
        }.bind(this),
        1000
      );
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    startAuthChecker() {
      this.authCycle = true;
      const authChecker = async () => {
        try {
          const data = (
            await this.$api.auth.getToken({
              phone_number: this.countryCode + this.user_phone,
            })
          ).data;
          localStorage.setItem("token", data.token);
          this.setToken(data.token);
          this.$emit("currentStartEvent");
          this.$router.push({
            name: "AccountMain",
          });
          this.modal.hide();
          this.stopTimer();
          this.authCycle = false;
        } catch (error) {
          switch (error.response.status) {
            case 400:
              // this.error = "Введен некорректный код.";
              break;
          }
        }
        if (this.authCycle) setTimeout(authChecker, 3000);
      };
      authChecker();
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.auth-form
  max-width: 360px
  text-align: left
  @media(max-width: $tablet)
    max-width: 312px
  &-text
    margin-bottom: 5px
    @media(max-width: $tablet)
      font-size: 14px
  &-input
    background: #1A316C
    border: 0
    border-radius: 9px
    color: #fff
    &:hover, &:active, &:focus
      background: #1A316C
      color: #fff !important
    &::placeholder
      color: #fff
      letter-spacing: 5px
  &-link
    display: inline-block
    margin-top: 20px
    text-decoration: underline
    @media(max-width: $tablet)
      font-size: 14px
    &:hover
      text-decoration: none
  &-code
    margin-bottom: 20px
  .timer
    color: $green
    font-size: 14px
  .phoneLink
    color: #4fc2f6
  .authCallButton
    display: block
</style>
