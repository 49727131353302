export default function (fetchData) {
  return {
    createPayment(payload) {
      return fetchData.post("cloud-sessions/rest/payment-proceeds", payload);
    },
    createPaymentNight(payload) {
      return fetchData.post(
        "cloud-sessions/rest/night-tariff-purchases",
        payload
      );
    },
    getDiscount() {
      return fetchData.get("cloud-sessions/rest/discount");
    },
  };
}
