import { createApp } from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import ApiPlugin from "./plugins/api.js";
import VueKinesis from "vue-kinesis";
import VueTelInput from "vue-tel-input";
import VueScrollTo from "vue-scrollto";
import VueYandexMetrika from "vue-yandex-metrika";
import VueClipboard from "vue3-clipboard";
import PowerGlitchPlugin from 'vue-powerglitch'
import "bootstrap";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue-tel-input/dist/vue-tel-input.css";
import "@/styles/style.sass";
import "./registerServiceWorker";

const globalOptions = {
  mode: "auto",
  autoDefaultCountry: false,
  autoFormat: false,
  defaultCountry: "RU",
  inputOptions: {
    placeholder: "",
    required: true,
    autofocus: true,
    showDialCode: false,
    autocomplete: false,
    id: "telInput",
  },
  dropdownOptions: {
    showDialCodeInSelection: true,
  },
  preferredCountries: [
    "RU",
    "UA",
    "BY",
    "MD",
    "AM",
    "TJ",
    "TM",
    "AZ",
    "UZ",
    "IL",
    "KG",
    "LV",
    "LT",
    "GE",
    "TR",
    "KZ",
  ],
  validCharactersOnly: true,
};

createApp(App)
  .use(store)
  .use(router)
  .use(VueYandexMetrika, {
    id: 89929383,
    router: router,
    env: process.env.NODE_ENV,
  })
  .use(ApiPlugin)
  .use(VueScrollTo, {
    duration: 300,
    offset: -120,
  })
  .use(VueKinesis)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(VueTelInput, globalOptions)
  .use(PowerGlitchPlugin)
  .mount("#app");
