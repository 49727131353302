export default {
  namespaced: true,
  state: {
    faqItems: [],
  },
  getters: {
    getFaqItems(state) {
      return state.faqItems;
    },
  },
  mutations: {
    SET_FAQ_ITEMS(state, payload) {
      state.faqItems = payload;
    },
  },
  actions: {
    async getFaqItems(context, payload) {
      const data = await payload.faq.faqItems();
      context.commit("SET_FAQ_ITEMS", data.data);
    },
  },
};
