export default function (fetchData) {
  return {
    poweron(payload) {
      return fetchData.post("cloud-sessions/rest/new", payload);
    },
    poweroff() {
      return fetchData.patch("cloud-sessions/rest/active");
    },
    sendData(payload) {
      return fetchData.post("cloud-sessions/rest/computer_operations", payload);
    },
    leaveQueue() {
      return fetchData.delete("cloud-sessions/rest/new");
    },
  };
}
