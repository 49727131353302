<template>
  <div>
    <kinesis-container>
      <page-title-section>
        Часто задаваемые <br />
        вопросы
      </page-title-section>
    </kinesis-container>
    <section class="help-center">
      <div class="container">
        <div class="row help-center__support">
          <div class="col-md-4">
            <div class="help-center__support-item">
              <div class="help-center__support-item__btn">
                <transparent-button
                  color="blue"
                  class="text-center"
                  width="500"
                  href="https://vk.com/im?sel=-190214203"
                  target="_blank"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Время ответа от 1 минуты"
                >
                  Тех-поддержка <br />
                  через ВК
                </transparent-button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="help-center__support-item">
              <div class="help-center__support-item__btn">
                <transparent-button
                  class="text-center"
                  width="500"
                  href="https://t.me/gatoga_bot"
                  target="_blank"
                  disabled
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Время ответа от 1 минуты"
                >
                  Тех-поддержка <br />
                  через TELEGRAM
                </transparent-button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="help-center__support-item">
              <div class="help-center__support-item__btn">
                <transparent-button
                  class="text-center"
                  width="500"
                  color="yellow"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Время ответа до 48 часов"
                  @click="copyMail"
                >
                  Тех-поддержка <br />
                  через E-MAIL
                </transparent-button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="help-center__search">
              <input type="text" v-model="request" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 help-center__items">
            <TransitionGroup
              tag="div"
              :css="false"
              @before-enter="onBeforeEnter"
              @enter="onEnter"
              @leave="onLeave"
            >
              <expansion-item
                v-for="item in filteredItems"
                :key="item.title"
                group="faq"
              >
                <template v-slot:label>{{ item.title }}</template>
                <div v-html="item.answer"></div>
              </expansion-item>
            </TransitionGroup>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PageTitleSection from "@/components/PageTitleSection";
import TransparentButton from "@/components/ui/TransparentButton";
import ExpansionItem from "@/components/ui/ExpansionItem";
import gsap from "gsap";
import { mapGetters } from "vuex";
import { Tooltip } from "bootstrap";

export default {
  name: "FAQ",
  components: {
    PageTitleSection,
    TransparentButton,
    ExpansionItem,
  },
  data() {
    return {
      request: ""
    };
  },
  mounted() {
    //Tooltip BS5 activate
    let tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    // eslint-disable-next-line no-unused-vars
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  },
  computed: {
    ...mapGetters({ getFaqItems: "faq/getFaqItems" }),
    faqItems() {
      return this.getFaqItems;
    },
    filteredItems() {
      let request = this.request;
      return this.faqItems.filter(function (elem) {
        if (request === "") return true;
        else {
          return (
            elem.title.toLowerCase().indexOf(request.toLowerCase()) > -1 ||
            elem.answer.toLowerCase().indexOf(request.toLowerCase()) > -1
          );
        }
      });
    },
  },
  methods: {
    onBeforeEnter(el) {
      el.style.opacity = 0;
      el.style.height = 0;
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        height: "auto",
        delay: el.dataset.index * 0.15,
        onComplete: done,
      });
    },
    onLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        height: 0,
        delay: el.dataset.index * 0.15,
        onComplete: done,
      });
    },
    copyMail() {
    	const email = 'admin@gatoga.ru'
    	
    	navigator.clipboard.writeText(email).then(() => { 
    		alert("Адрес скопирован в буфер обмена")
    	}).catch(function(err) { 
    		console.error(err) 
    	})
    }
  },
};
</script>

<style lang="sass" scoped>
.page-title
  margin-top: -93px
  @media(max-width: $mobile)
    margin-top: 0
.help-center
  padding-top: 93px
  padding-bottom: 140px
  &__support-item__btn
    margin-bottom: 15px
    text-align: center
    .transparent-btn
      padding: 15px 0
      height: auto
      font-weight: 400
      &:hover
        color: $black
  &__support-item__time
    font-size: $regular-size
    letter-spacing: 0.75px
    margin-bottom: 30px
  &__search
    position: relative
    margin-top: 30px
    margin-bottom: 40px
    &:before
      content: ""
      width: 13px
      height: 13px
      display: inline-block
      background: url("@/assets/img/search.svg") no-repeat
      background-size: cover
      position: absolute
      top: 0
      left: 14.25px
      bottom: 0
      margin: auto
    input
      width: 100%
      height: 40px
      border-radius: 9px
      background: #313637
      font-size: $regular-size
      color: #ffffff
      border: 0
      outline: 0
      padding: 0 15px 0 40px
  &__items
    min-height: 500px
.list-enter-active,
.list-leave-active
  transition: all 0.5s ease
.list-enter-from,
.list-leave-to
  opacity: 0
  transform: translateX(30px)
</style>
